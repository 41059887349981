import { $get } from '@js/authRequest'
import { Toast } from 'antd-mobile'

export async function getTreeRequest () {
  const res = await $get('/api/xapc/professionaltag')
  if (res.status !== 1) {
    Toast.fail('获取专业失败', 1)
    throw new Error(res.message)
  }
  return res.data
}
