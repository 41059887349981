import { $post } from '@/assets/js/authRequest'
import { compress } from '@/assets/js/tool'

export async function sendSMS (mobile, captchaAtt) {
  const res = await $post('/api/common/smsCaptcha', {
    template_code: 'SMS_182920835',
    mobile: compress(mobile),
    captchaAtt
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
