import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './Border.module.less'

export default class Border extends Component {
  getClassName () {
    const { top, bottom, left, right, radius } = this.props
    const className = [style.container]
    if (!top) {
      className.push(style['no-top'])
    }
    if (!bottom) {
      className.push(style['no-bottom'])
    }
    if (!left) {
      className.push(style['no-left'])
    }
    if (!right) {
      className.push(style['no-right'])
    }
    if (!radius) {
      className.push(style['no-radius'])
    }
    return className.join(' ')
  }

  render () {
    return (
      <div className={this.getClassName()}>
        <div className={style.inner}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Border.propTypes = {
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  radius: PropTypes.bool
}

Border.defaultProps = {
  top: true,
  bottom: true,
  left: true,
  right: true,
  radius: true
}
