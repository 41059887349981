import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './Row.module.less'

export default class Row extends Component {
  state = {
    active: false
  }

  clickHandle () {
    this.props.clickHandle()
    this.setState({ active: true })
    setTimeout(() => {
      this.setState({ active: false })
    }, 100)
  }

  render () {
    const rowClass = [style.row]
    if (this.state.active) {
      rowClass.push(style.active)
    }
    const d = this.props.data
    return (
      <div className={rowClass.join(' ')} onClick={() => this.clickHandle()}>
        <div className={style['header-line']}>
          <div className={style['case-no']}>{d.caseno_desc || d.case_no || d.acceptno_desc || d.accept_no}</div>
          <div className={style['case-status']}>{d.status}</div>
        </div>
        <div className={style.line}>申请人：{d.applicants.map(l => l.name).join('、')}</div>
        {d.applicant_agents.length ? <div className={style.line}>申请人代理人：{d.applicant_agents.map(l => l.name).join('、')}</div> : null}
        <div className={style.line}>被申请人：{d.respondents.map(l => l.name).join('、')}</div>
        {d.respondents_agents.length ? <div className={style.line}>被申请人代理人：{d.respondents.map(l => l.name).join('、')}</div> : null}
        {d.arbitrators ? <div className={style.line}>仲裁员：{d.arbitrators.replace(/,/g, '、')}</div> : null}
      </div>
    )
  }
}

Row.propTypes = {
  // data: PropTypes.object,
  clickHandle: PropTypes.func
}

Row.defaultProps = {
  data: null,
  clickHandle: () => {}
}
