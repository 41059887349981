import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../../components/Component'
import { connect } from 'react-redux'
import style from './index.module.less'
import { List, Modal, Toast } from 'antd-mobile'
import Icon from '../../components/Icon'
import { logout } from '../../redux/actions'

const alert = Modal.alert

const Item = List.Item

class ApproveDetail extends Component {
  state = {
  }

  logout () {
    Toast.show('退出中', 120)
    this.$post('/api/common/logout').then(res => {
      Toast.hide()
      if (res.status === 1) {
        Toast.success('退出成功', 1)
        this.props.logout()
        setTimeout(() => {
          this.props.history.replace('/login')
        }, 1000)
      } else {
        Toast.fail(res.message)
      }
    }, () => {
      Toast.hide()
    })
  }

  logoutConfirm () {
    alert('提示', '您确定要退出吗？', [
      { text: '取消' },
      { text: '确定', onPress: () => this.logout() }
    ])
  }

  componentDidMount () {
  }

  render () {
    const d = this.props.userInfo
    return (
      <div className={style.container} id='mine'>
        <div className={style.header}>
          <div className={style['header-name']}>{d.realname}</div>
          <div className={style['header-mobile']}>{d.mobile}</div>
        </div>
        <div className={style.operate}>
          <List>
            <Item arrow='horizontal' thumb={<Icon name='edit' size='1.2rem' color='#FF9B37' />} onClick={() => this.props.history.push('/admin/modify-password')}>修改密码</Item>
            <Item arrow='horizontal' thumb={<Icon name='sign-out' size='1.2rem' color='#0039BF' />} onClick={() => this.logoutConfirm()}>退出</Item>
          </List>
        </div>
      </div>
    )
  }
}

ApproveDetail.propTypes = {
  // id: PropTypes.string,
}

ApproveDetail.defaultProps = {
  id: '',
  data: {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    logout: () => dispatch(logout())
  }
})

export default connect(mapState, mapDispatch)(ApproveDetail)
