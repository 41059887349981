import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Approve from '../Approve/Index'
import BusinessLeader from './RegisterCase/BusinessLeader'
import BusinessLeaderHistory from './RegisterCase/BusinessLeaderHistory'
import SecretaryHistory from './RegisterCase/SecretaryHistory'

export default class RegisterCase extends Component {
  // 处理业务领导表单数据
  businessLeaderBeforeSubmit (data) {
    data.vars = {
      processerid: data.processerid,
      processer: data.processer,
      processerid_tutor: data.processerid_tutor,
      processer_tutor: data.processer_tutor
    }
    delete data.processerid
    delete data.processer
    delete data.processerid_tutor
    delete data.processer_tutor
    return Promise.resolve(data)
  }

  // 在提交前处理数据
  beforeSubmit (instance, data) {
    const { currentStep } = instance
    switch (currentStep.step_key) {
      case 'lian02':
        return this.businessLeaderBeforeSubmit(data)
    }
    return Promise.resolve(data)
  }

  // 渲染历史
  renderHistory (action, renderFn) {
    switch (action.step_key) {
      case 'lian01':
        return <SecretaryHistory key={action.id} action={action} />
      case 'lian02':
        return <BusinessLeaderHistory key={action.id} action={action} />
    }
    return renderFn(action)
  }

  // 渲染当前操作步
  renderCurrentStep (form, currentStep, renderFn) {
    switch (currentStep.step_key) {
      case 'lian02':
        return <BusinessLeader form={form} currentStep={currentStep} />
    }
    return renderFn(currentStep)
  }

  render () {
    const { flowInstanceid, flowid } = this.props
    return (
      <Approve
        flowInstanceid={flowInstanceid}
        flowid={flowid}
        renderHistory={(action, renderFn) => this.renderHistory(action, renderFn)}
        renderCurrentStep={(formRef, currentStep, renderFn) => this.renderCurrentStep(formRef, currentStep, renderFn)}
        initialCurrentStepFormData={() => ({ })}
        beforeSubmit={(instance, data) => this.beforeSubmit(instance, data)}
        afterAction={() => this.props.goBack()}
      />
    )
  }
}

RegisterCase.propTypes = {
  flowInstanceid: PropTypes.string,
  goBack: PropTypes.func
}

RegisterCase.defaultProps = {
  flowInstanceid: '',
  goBack: () => {}
}
