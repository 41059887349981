import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { List, TextareaItem, Picker } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../../Component'
import { connect } from 'react-redux'
import style from './StepInput.module.less'

const list = [
  [
    {
      label: '是',
      value: '是'
    },
    {
      label: '否',
      value: '否'
    }
  ]
]

class StepTwoInput extends Component {
  state = {
    keyTitleMap: {}
  }

  initKeyTitleMap () {
    const map = {}
    const list = _.get(this.props.currentStep || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
    })
    this.setState({
      keyTitleMap: map
    })
  }

  componentDidUpdate (prev) {
    if (this.props.currentStep !== prev.currentStep) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  render () {
    const d = this.props.currentStep
    if (!d) {
      return null
    }
    const { getFieldProps, getFieldError } = this.props.form
    const remarkTitle = d.remark_alias || '备注'
    const k = this.state.keyTitleMap
    return (
      <div>
        <List renderHeader={() => d.step_name}>
          <List.Item extra={<span className={style.value}>{this.props.userInfo.realname}</span>}>操作者</List.Item>
          <TextareaItem
            {...getFieldProps('closure_bizleader_remark', { rules: [{ required: true }] })}
            error={getFieldError('closure_bizleader_remark')}
            title={k.closure_bizleader_remark}
            autoHeight
            placeholder={`请输入${k.closure_bizleader_remark}`}
            clear
            required
          />
          <Picker
            {...getFieldProps('closure_isonlineaudit', { rules: [{ required: true }] })}
            data={list}
            cascade={false}
            extra='请选择'
            cols={1}
          >
            <List.Item arrow='horizontal' error={getFieldError('closure_isonlineaudit')}>{k.closure_isonlineaudit}</List.Item>
          </Picker>
          <TextareaItem
            {...getFieldProps('remark')}
            title={remarkTitle}
            autoHeight
            placeholder={`请输入${remarkTitle}`}
            clear
          />
        </List>
      </div>
    )
  }
}

StepTwoInput.propTypes = {
  form: PropTypes.object,
  currentStep: PropTypes.object,
  caseDetail: PropTypes.object,
  otherData: PropTypes.object
}

StepTwoInput.defaultProps = {
  form: null,
  currentStep: null,
  caseDetail: null,
  otherData: null
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(StepTwoInput)
