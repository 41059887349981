import React from 'react'
import { Redirect } from 'react-router-dom'
import Admin from '../views/Admin.js'
import Login from '../views/Login'
import WXLogin from '../views/WXLogin'
import SendSmsValidate from '../views/SendSmsValidate/Index.js'
import LoginValidate from '../views/LoginValidate/Index.js'
export const RouterConfig = [
  {
    path: '/login',
    component: Login,
    push: 'back',
    pop: 'forward',
    exact: true
  },
  {
    path: '/send-sms-validate',
    component: SendSmsValidate,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/login-validate',
    component: LoginValidate,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/admin',
    component: Admin,
    push: 'forward',
    pop: 'back'
  },
  {
    path: '/wework',
    component: WXLogin,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/',
    component: () => <Redirect push to='/admin' />,
    exact: true
  }

  // {
  //   path: '/apply-for-a-deferment',
  //   component: ApplyForADeferment,
  //   sceneConfig: {
  //     enter: 'from-bottom',
  //     exit: 'to-bottom'
  //   }
  // },
]
