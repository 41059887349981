import React from 'react'
import classnames from 'classnames'
import style from './sidebar.module.less'
import AdminDrawerContext from './AdminDrawerContext'
import ArbitratorSidebar from './ArbitratorSidebar'

function AdminSidebar () {
  return (
    <div className={style.container}>
      <AdminDrawerContext.Consumer>
        {({ currentDrawer, drawerOpening, setArbitratorFormData, setDrawerOpening }) => {
          return (
            <ArbitratorForm noCurrent={currentDrawer !== 'arbitrator'} callback={setArbitratorFormData} hide={() => setDrawerOpening(false)} />
          )
        }}
      </AdminDrawerContext.Consumer>
    </div>
  )
}

function ArbitratorForm ({ noCurrent, callback, hide }) {
  const names = classnames({ [style.hide]: noCurrent })
  return (
    <div className={names}>
      <ArbitratorSidebar onChange={callback} hide={hide} />
    </div>
  )
}

export default AdminSidebar
