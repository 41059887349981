import React, { Component } from 'react'
import { NavBar, Icon, Toast } from 'antd-mobile'
import { connect } from 'react-redux'
import style from './Index.module.less'
import qs from 'querystring'
import { setSendTime } from '@/redux/actions'
import { awaitWrap } from '@/assets/js/tool'
import { sendSMS } from './ajax'

class Main extends Component {
  state = {

  }

  async sendHandle (mobile, arr) {
    Toast.loading('校验中', 120)
    const [e] = await awaitWrap(sendSMS(mobile, arr))
    if (e !== null) {
      Toast.fail(e.message, 2)
    } else {
      Toast.hide()
    }
    return e === null
  }

  componentDidMount () {
    const s = qs.parse(location.search.slice(1))
    if (typeof s.mobile !== 'string') {
      Toast.fail('手机号不存在', 2)
      return undefined
    }
    if (!/^1\d{10}$/.test(s.mobile)) {
      Toast.fail('手机号格式不正确', 2)
      return undefined
    }
    window.sliderCaptcha({
      id: 'captcha',
      width: 280,
      height: 150,
      sliderL: 42,
      sliderR: 12,
      offset: 8,
      loadingText: '正在加载中...',
      failedText: '再试一次',
      barText: '向右滑动填充拼图',
      repeatIcon: 'fa fa-redo',
      remoteUrl: '/api/common/smsCaptcha',
      setSrc: function () {
        return `/slidercaptcha/images/Pic${Math.floor(Math.random() * 4)}.jpg`
      },
      verify: async (arr, spliced) => {
        if (!spliced) {
          throw new Error('验证失败')
        }
        const res = await this.sendHandle(s.mobile, arr)
        if (res) {
          Toast.success('短信发送成功')
          this.props.setSendTime()
          this.props.history.goBack()
        } else {
          throw new Error('验证失败')
        }
      }
    })
  }

  componentWillUnmount () {

  }

  render () {
    return (
      <div ref={this.containerRef} className={style.container}>
        <div className={style.header}>
          <NavBar ref={this.navbarRef} mode='light' icon={<Icon type='left' size='lg' />} onLeftClick={() => this.props.history.goBack()}>安全验证</NavBar>
        </div>
        <div className={style.body}>
          <div id='captcha' />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    sendTime: state.basic.sendTime,
    mobile: state.basic.mobile
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSendTime: () => dispatch(setSendTime(Date.now()))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
