import React from 'react'
import { NavBar, Icon, Toast, ActionSheet } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import style from './CaseDetail.module.less'
import { TextList, TextItem, TextTitle, ContentItem } from '../components/TextList'
import Border from '../components/Border'
import { formatCaseDataLicenseAndMobile } from '@/assets/js/tool'

function copy (text) {
  // text是复制文本
  // 创建input元素
  if (!document || !document.body) {
    return undefined
  }
  const el = document.createElement('input')
  // 给input元素赋值需要复制的文本
  el.setAttribute('value', text)
  // 将input元素插入页面
  document.body.appendChild(el)
  // 选中input元素的文本
  el.select()
  // 复制内容到剪贴板
  document.execCommand('copy')
  // 删除input元素
  document.body.removeChild(el)
  Toast.success('复制成功')
}

class CaseDetail extends Component {
  state = {
    height: 0,
    caseDetail: {},
    loading: false
  }

  status = true
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 获取数据
  getData () {
    Toast.loading('数据加载中', 120)
    this.setState({ loading: true })
    const id = this.props.match.params.caseid
    this.$get(`/api/xapc/case/${id}`)
      .then(res => {
        Toast.hide()
        this.setState({ loading: false })
        if (this.status === false) {
          return false
        }
        if (res.status === 1) {
          if (res.data) {
            formatCaseDataLicenseAndMobile(res.data)
          }
          this.setState({
            caseDetail: res.data
          })
        } else {
          Toast.fail(res.message, 2)
        }
      }).catch(() => {
        Toast.hide()
        this.setState({ loading: false })
      })
  }

  // 获得仲裁员
  formatArbitrators (d) {
    const list = []
    if (d.arbitrator) {
      list.push(d.arbitrator)
    }
    if (d.arbitrator1) {
      list.push(d.arbitrator1)
    }
    if (d.arbitrator2) {
      list.push(d.arbitrator2)
    }
    if (list.length) {
      return list.join('、')
    }
    return false
  }

  // 格式化金钱
  formatMoney (money) {
    const m = String(money)
    if (!/^\d*$/.test(m)) {
      return m
    }
    return m.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  componentDidMount () {
    this.getData()
  }

  componentWillUnmount () {
    this.status = false
  }

  // 渲染案件状态标志
  renderStatus (status) {
    return (
      <div className={style['case-status']}>{status}</div>
    )
  }

  // 渲染人员列表
  renderApplicants (type, list) {
    if (!list.length) {
      return null
    }
    return (
      <>
        <TextTitle>{type}</TextTitle>
        <TextList>
          {list.map((li, i) => {
            const options = []
            if (li.mobile) {
              options.push(`tel:${li.mobile}`)
            }
            if (li.address) {
              options.push(`${li.address}`)
            }
            options.push('取消')
            return (
              <ContentItem
                key={i} titleContent={<span className={style['order-number']}>{i + 1}</span>} value={li.name} onClick={() => {
                  ActionSheet.showActionSheetWithOptions({
                    options: options,
                    cancelButtonIndex: options.length - 1
                  }, i => {
                    if (i === options.length - 1) {
                      return undefined
                    }
                    const v = options[i]
                    if (v.indexOf('tel:') === 0) {
                      window.location.href = v
                    } else {
                      copy(v)
                    }
                  })
                }}
              />
            )
          })}
        </TextList>
      </>
    )
  }

  // 渲染仲裁请求
  renderClaim (list) {
    if (!list.length) {
      return null
    }
    return (
      <>
        <TextTitle>仲裁请求</TextTitle>
        <TextList>
          {list.map((li, i) => {
            let value = ''
            if (li.type === '标的收费') {
              value = li.desc
            } else if (li.type !== '仲裁费') {
              value = `${li.desc || ''}（${li.type} - ${this.formatMoney(li.fee_acceptance)}）`
            } else {
              value = `${li.desc || ''}（${li.type}）`
            }
            return (
              <ContentItem key={i} titleContent={<span className={style['order-number']}>{i + 1}</span>} value={value} />
            )
          })}
        </TextList>
      </>
    )
  }

  renderDetail () {
    if (this.state.loading) {
      return <div />
    }
    const a = this.state.caseDetail
    const arbitrator = this.formatArbitrators(a)
    return (
      <div className={style.detail}>
        <Border left={false} right={false} bottom={false} radius={false}>
          <TextTitle>案件基本信息</TextTitle>
        </Border>
        <TextList>
          <TextItem title='案号' value={a.caseno_desc || a.case_no || a.acceptno_desc || a.accept_no || '无'} />
          <TextItem title='案件状态' value={this.renderStatus(a.status)} />
          {arbitrator ? <TextItem title='仲裁庭' value={arbitrator} /> : null}
          <TextItem title='案由' value={a.cause_action || '无'} />
          <TextItem title='仲裁程序' value={a.procedure || '无'} />
          <TextItem title='争议金额' value={a.dispute_amount ? this.formatMoney(a.dispute_amount) : '无'} />
          <TextItem title='仲裁费' value={a.fee ? this.formatMoney(a.fee) : '无'} />
          <TextItem title='办案秘书' value={a.processer || '无'} />
        </TextList>
        {this.renderApplicants('申请人', a.applicants || [])}
        {this.renderApplicants('申请人代理人', a.applicant_agents || [])}
        {this.renderApplicants('被申请人', a.respondents || [])}
        {this.renderApplicants('被申请人代理人', a.respondents_agents || [])}
        {this.renderClaim(a.claim ? a.claim.items || [] : [])}
      </div>
    )
  }

  render () {
    return (
      <div ref={this.containerRef} className={style.container}>
        <div className={style.header}>
          <NavBar ref={this.navbarRef} mode='light' icon={<Icon type='left' size='lg' />} onLeftClick={() => this.props.history.goBack()}>案件详情</NavBar>
        </div>
        {this.renderDetail()}

      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(CaseDetail)
