import React from 'react'
import axios from 'axios'
import { Toast } from 'antd-mobile'
import { setToken } from '../redux/actions'
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 1000 * 120
})
// axios.defaults.timeout = 1000 * 30;
// axios.defaults.headers['Content-Type'] = 'application/json';

/**
 * 请求方法
 * @param {*} type 请求类型
 */
function request (type) {
  return function (url, params = {}, config = {}) {
    const { token, userInfo = {} } = this.props
    instance.defaults.headers.Authorization = `Bearer ${token}`
    const that = this
    const data = {
      uid: userInfo.id
      // login_realname: userInfo.realname,
      // login_mobile: userInfo.mobile,
      // login_group_id: userInfo.group_id
    }
    if (type === 'post' || type === 'put') {
      if (params instanceof FormData) {
        config = { ...config, params: { ...config.params, uid: userInfo.id } }
      } else {
        params = Object.assign(params, data)
      }
    } else {
      if (!params.params) {
        params.params = {}
      }
      params.params = Object.assign(params.params, data)
    }
    return new Promise((resolve, reject) => {
      instance[type](url, params, config)
        .then(res => {
          resolve(res.data)
        })
        .catch(e => {
          const { status, data } = e.response || { status: 504 }
          if (status === 401 || (status === 500 && data.message === '登录超时')) {
            if (!this.props.token) {
              return reject(e)
            }
            that.props.setToken(undefined)
            Toast.fail(token ? '登录超时' : '请先登录', 2)
            setTimeout(() => {
              that.props.history ? that.props.history.push('/login') : location.reload()
            }, 2000)
            return reject(e)
          }
          if (status === 504) {
            Toast.fail('网络异常', 2)
          } else {
            Toast.fail(data.message, 2)
          }
          reject(e)
        })
    })
  }
}
export default class Component extends React.Component {
  $get = request('get')
  $post = request('post')
  $put = request('put')
  $delete = request('delete')
  state = {
    _fileList: [], // 已有文书列表
    _fileListLoaded: false,
    _fileCreating: false,
    _printing: false // 打印中
  }

  componentWillMount () {
    if (!this.props.token && this.props.history) {
      this.props.history.push('/login')
    }
  }

  // 获取案件文书列表
  $getFileList (caseid) {
    this.setState({
      _fileListLoaded: false
    })
    return new Promise((resolve, reject) => {
      this.$get(`/api/xapc/case/file/${caseid}`, {
        params: {
          current: 1,
          page_size: 1000
        }
      })
        .then(res => {
          if (res.status === 1) {
            const data = {
              _fileList: res.data.list,
              _fileListLoaded: true
            }
            this.setState(data, () => {
              resolve(data)
            })
          } else {
            reject(new Error(res.message))
          }
        })
    })
  }

  // 生成指定文书
  $createDoc (caseid, fileNames = [], bizid = '', notice = '') {
    Toast.loading(notice || '文书生成中', 120)
    this.setState({
      _fileCreating: true
    })
    const data = {
      caseid,
      filename: fileNames
    }
    if (bizid) {
      data.bizid = bizid
    }
    return new Promise((resolve, reject) => {
      this.$post('/api/xapc/case/file', data).then(res => {
        Toast.hide()
        this.setState({
          _fileCreating: false
        })
        if (res.status === 1) {
          this.$getFileList(caseid)
            .then(() => resolve(res.data))
        } else {
          throw new Error(res.message)
        }
        return res.data
      }).catch(e => {
        Toast.hide()
        this.setState({
          _fileCreating: false
        })
        Toast.fail(e)
      })
    })
  }
}

export function mapStateToProps (fn = () => {}) {
  return function (state) {
    const basic = {
      userInfo: state.basic.userInfo,
      token: state.basic.token
    }
    const newState = fn(state)
    return {
      ...basic,
      ...newState
    }
  }
}

export function mapDispatchToProps (fn = () => {}) {
  return function (dispatch) {
    const basic = {
      setToken: (i) => dispatch(setToken(i))
    }
    const newState = fn(dispatch)
    return {
      ...basic,
      ...newState
    }
  }
}
