import React, { useEffect, useMemo, useState, useRef } from 'react'
import Pop from '../Pop/Index'
import style from './Index.module.less'
import { getTreeRequest } from './ajax'
import { awaitWrap } from '@/assets/js/tool'
import classNames from 'classnames'
import Icon from '@/components/Icon'
import { Toast } from 'antd-mobile'

async function getTree (setList, setMap) {
  const [e, d] = await awaitWrap(getTreeRequest())
  if (e === null && d !== null) {
    const o = {}
    formatMap(d, o)
    setMap(o)
    setList(d)
  }
}

function formatMap (list, map) {
  if (list instanceof Array && list.length > 0) {
    list.forEach(li => {
      map[li.id] = li.title
      formatMap(li.children, map)
    })
  }
}

function Tree ({ value = [], current, setCurrent }) {
  return value.map((li, i) => {
    return <Leaf data={li} current={current} setCurrent={setCurrent} key={i} />
  })
}

function Leaf ({ data, current, setCurrent }) {
  const [visible, setVisible] = useState(false)
  const active = useMemo(() => {
    if (!data) {
      return false
    }
    return current === data.id
  }, [data, current])
  const hasChildren = useMemo(() => {
    return data && data.children instanceof Array && data.children.length > 0
  }, [data])
  if (!data) {
    return <span />
  }
  return (
    <div className={style.tree}>
      <div
        className={classNames(style.leaf, { [style.active]: active })} onClick={() => {
          setCurrent(current !== data.id ? data.id : '')
        }}
      >
        {hasChildren ? (
          <span
            className={style.arrow} onClick={(e) => {
              e.stopPropagation()
              setVisible(!visible)
            }}
          >
            <Icon name={`arrow-${visible ? 'up' : 'down'}-filling`} />
          </span>
        ) : (
          <span className={style.arrow} />
        )}
        <span className={style.title}>{data.title}</span>
      </div>
      {visible && <Tree value={data.children} current={current} setCurrent={setCurrent} />}
    </div>
  )
}

function Main ({ value, onChange }) {
  const ref = useRef(null)
  const [list, setList] = useState([])
  const [current, setCurrent] = useState('')
  const [map, setMap] = useState({})
  useEffect(() => {
    getTree(setList, setMap)
  }, [])
  useEffect(() => {
    setCurrent(value)
  }, [value])
  const name = useMemo(() => {
    const o = map[value]
    if (o) {
      return o
    }
    return ''
  }, [map, value])
  return (
    <Pop
      ref={ref} over={(
        <div className={style.container}>
          <div className={style['btns-line']}>
            <button onClick={() => {
              onChange('')
              ref.current.hide()
            }}
            >清空
            </button>
            <span className={style['line-title']}>擅长专业</span>
            <button onClick={() => {
              if (!current) {
                Toast.info('请选择擅长专业', 1)
              }
              onChange(current)
              ref.current.hide()
            }}
            >确定
            </button>
          </div>
          <div className={style.list}>
            <Tree value={list} current={current} setCurrent={setCurrent} />
          </div>
        </div>
      )}
    >
      <div className={style.select}>
        <span className={classNames({ [style.placeholder]: value === '' })}>{name || '请选择'}</span>
        <Icon name='arrow-right' />
      </div>
    </Pop>
  )
}

export default Main
