import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import style from './SelectorTags.module.less'

// 一个可选标签
function Tag ({ active, label, value, onClick }) {
  const names = classnames(style.tag, {
    [style.active]: active
  })
  return (
    <div className={names} onTouchEnd={() => onClick(value)}>
      {label}
    </div>
  )
}

Tag.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func
}

Tag.defaultProps = {
  active: false,
  label: '',
  value: '',
  onClick: () => {}
}

// 可选标签列表
function SelectorTags ({ options, value, onChange, single = false }) {
  const [selectedList, setSelectedList] = useState(value)
  useEffect(() => {
    setSelectedList(value)
  }, [value])
  return (
    <div className={style.container}>
      {options.map((li, i) => {
        return <Tag key={i} label={li.label} value={li.value} active={selectedList.indexOf(li.value) !== -1} onClick={() => changeTag(li.value, single, selectedList, setSelectedList, onChange)} />
      })}
    </div>
  )
}

// 改选选中项
function changeTag (value, single, selectedList, setSelectedList, onChange) {
  let list = [...selectedList]
  const index = list.indexOf(value)
  if (single) {
    list = [value]
  } else if (index === -1) {
    list.push(value)
  } else {
    list.splice(index, 1)
  }
  setSelectedList(list)
  onChange(list)
}

SelectorTags.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func
}

SelectorTags.defaultProps = {
  options: [],
  value: [],
  onChange: () => {}
}

export default SelectorTags
