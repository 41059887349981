import React, { Component } from 'react'
import { Modal, Toast } from 'antd-mobile'
import { connect } from 'react-redux'
import style from './Index.module.less'
import { setSendTime } from '@/redux/actions'
import { awaitWrap } from '@/assets/js/tool'
import { sendSMS } from './ajax'

class Main extends Component {
  state = {
    ready: false
  }

  async sendHandle (mobile, arr) {
    Toast.loading('校验中', 120)
    const [e] = await awaitWrap(sendSMS(mobile, arr))
    if (e !== null) {
      Toast.fail(e.message, 2)
    } else {
      Toast.hide()
    }
    return e === null
  }

  init () {
    if (this.state.ready) {
      return false
    }
    this.setState({ ready: true })
    window.sliderCaptcha({
      id: 'captcha',
      width: 230,
      height: 160,
      sliderL: 42,
      sliderR: 12,
      offset: 8,
      loadingText: '正在加载中...',
      failedText: '再试一次',
      barText: '向右滑动填充拼图',
      repeatIcon: 'fa fa-redo',
      remoteUrl: '/api/common/smsCaptcha',
      setSrc: function () {
        return `/slidercaptcha/images/Pic${Math.floor(Math.random() * 4)}.jpg`
      },
      verify: async (arr, spliced) => {
        if (!spliced) {
          throw new Error('验证失败')
        }
        const res = await this.sendHandle(this.props.mobile || this.props.userInfoMobile, arr)
        if (res) {
          Toast.success('短信发送成功')
          this.props.setSendTime()
          this.props.hide()
        } else {
          throw new Error('验证失败')
        }
      }
    })
  }

  destory () {
    const o = document.querySelector('#captcha')
    if (o) {
      this.setState({ ready: false })
      o.innerHTML = ''
    }
  }

  componentDidUpdate (props) {
    if (props.visible !== this.props.visible) {
      if (this.props.visible) {
        this.init()
      } else {
        this.destory()
      }
    }
  }

  render () {
    return (
      <Modal transparent visible={this.props.visible} onClose={() => this.props.hide()}>
        <div id='captcha' className={style.pic} />
      </Modal>
    )
  }
}

function mapStateToProps (state) {
  return {
    sendTime: state.basic.sendTime,
    userInfoMobile: state.basic.userInfo.mobile
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSendTime: () => dispatch(setSendTime(Date.now()))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
