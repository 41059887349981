import React, { Component } from 'react'
import { mapStateToProps, mapDispatchToProps } from '../../components/Component'
import { connect } from 'react-redux'
import style from './Index.module.less'
import { NavBar, Icon, Toast, List, InputItem, Button } from 'antd-mobile'
import classNames from 'classnames'
import { logout, setLoginMobile, setSendTime, setToken, setUserInfo } from '../../redux/actions'
import SendCode from '../../components/SendSmsValidate/Index'
import { decompress } from '@/assets/js/tool'
import { $post } from '@/assets/js/request'
// const Item = List.Item

const maxTime = 60

class ApproveDetail extends Component {
  state = {
    active: false,
    disabled: false,
    username: '',
    password: '',
    code: '',
    visible: false,
    countDown: 0,
    sendVisible: false,
    time: '',
    mobile: ''
  }

  logout () {
    Toast.show('退出中', 120)
    this.$post('/api/common/logout').then(res => {
      Toast.hide()
      if (res.status === 1) {
        Toast.success('退出成功', 1)
        setTimeout(() => {
          this.props.history.replace('/login')
        }, 1000)
      } else {
        Toast.fail(res.message)
      }
    }, () => {
      Toast.hide()
    })
  }

  /**
     * 计时器
     */
  runTime () {
    setTimeout(() => {
      const time = this.state.countDown - 1
      if (time >= 0) {
        this.setState({
          countDown: time
        }, () => {
          this.runTime()
        })
      } else {
        this.setState({
          disabled: false,
          countDown: 0
        })
      }
    }, 1000)
  }

  /**
   * 初始化上一次发送验证码时间
   */
  initSendTime (lastTime) {
    const now = Date.now()
    if (!lastTime) {
      return false
    }
    const num = Math.round((now - lastTime) / 1000)
    if (num < maxTime) {
      this.setState({
        countDown: maxTime - num,
        disabled: true
      }, () => {
        this.runTime()
      })
    } else {
      this.setState({
        disabled: false,
        countDown: 0
      })
    }
  }

  /**
     * 发送验证码
     */
  sendSMS () {
    if (!this.state.mobile) {
      Toast.info('手机号不能为空', 2)
      return undefined
    }
    this.props.setLoginMobile(this.state.mobile)
    this.setState({ sendVisible: true })
  }

  async submit () {
    Toast.show('提交中', 120)
    $post('/api/common/vuser_login', {
      password: this.state.password,
      username: this.state.username,
      verification: this.state.code,
      verify_plus: 'sms'
    }).then((res) => {
      if (res.status !== 1) {
        Toast.fail(res.message || '提交失败')
      } else {
        const { token, userInfo } = res.data
        if (userInfo.mobile && typeof userInfo.mobile === 'string') {
          userInfo.mobile = decompress(userInfo.mobile)
        }
        this.props.setUserInfo(userInfo)
        this.props.setToken(token)
        Toast.success('验证成功', 1, () => {
          if (userInfo.is_expire === 1) {
            this.props.history.push('/admin/modify-password')
          } else {
            this.props.history.push('/admin')
          }
        })
      }
    }, () => {
      Toast.fail('修改密码失败', 1)
    })
  }

  initData () {
    const h = this.props.history
    if (h && h.location && h.location.state) {
      const s = h.location.state
      if (s.mobile) {
        this.setState({
          username: s.username,
          password: s.password,
          mobile: decompress(s.mobile)
        })
      }
    }
  }

  filterMobile () {
    const m = this.state.mobile
    if (m) {
      return `${m.slice(0, 3)}****${m.slice(7)}`
    }
  }

  componentDidMount () {
    this.initData()
    if (this.props.sendTime) {
      this.initSendTime(this.props.sendTime)
    }
  }

  componentDidUpdate (props) {
    if (props.sendTime !== this.props.sendTime && this.props.sendTime) {
      this.initSendTime(this.props.sendTime)
    }
  }

  render () {
    return (
      <div className={style.container} id='modifyPassword'>
        <SendCode mobile={this.state.mobile} visible={this.state.sendVisible} hide={() => this.setState({ sendVisible: false })} />
        <div className={style.header}>
          <NavBar
            ref={this.navbarRef}
            mode='light'
            icon={<Icon type='left' size='lg' />}
            onLeftClick={() => {
              this.props.logout()
              this.props.history.goBack()
            }}
          >安全验证
          </NavBar>
        </div>
        <div className={style.inner}>
          <List>
            <InputItem value={this.filterMobile()} disabled>手机号</InputItem>
            <InputItem
              className={style.line}
              clear
              placeholder='请输入验证码'
              maxLength={4}
              value={this.state.code}
              onChange={e => this.setState({ code: e })}
              extra={(
                <button
                  className={classNames(style.send, { [style.active]: this.state.active, [style.disabled]: this.state.disabled })} onClick={() => {
                    if (!this.state.disabled) {
                      this.setState({ active: true })
                      setTimeout(() => {
                        this.setState({ active: false })
                      }, 300)
                      this.sendSMS()
                    }
                  }}
                >
                  {this.state.countDown >= 0 && this.state.disabled ? this.state.countDown : '发送短信'}
                </button>
              )}
            >验证码
            </InputItem>
          </List>
          <div className={style.btn}>
            <Button type='primary' onClick={() => this.submit()}>提交</Button>
          </div>
        </div>
      </div>
    )
  }
}

ApproveDetail.propTypes = {
  // id: PropTypes.string,
}

ApproveDetail.defaultProps = {
  id: '',
  data: {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo,
    sendTime: state.basic.sendTime
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    setLoginMobile: (i) => dispatch(setLoginMobile(i)),
    setSendTime: () => dispatch(setSendTime(Date.now())),
    logout: () => dispatch(logout()),
    setToken: (i) => dispatch(setToken(i)),
    setUserInfo: (i) => dispatch(setUserInfo(i))
  }
})

export default connect(mapState, mapDispatch)(ApproveDetail)
