import React from 'react'
import moment from 'moment'
import ReactDOM from 'react-dom'
import './index.less'
import Routers from './router/Routers'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import { PersistGate } from 'redux-persist/lib/integration/react'
import 'moment/locale/zh-cn'
import './assets/font/iconfont.css'
moment.locale('zh-cn')

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <React.StrictMode> */}
      <Routers />
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
