import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Approve from '../Approve/Index'
import SecretaryHistory from './Preservation/SecretaryHistory'

export default class Preservation extends Component {
  // 在提交前处理数据
  beforeSubmit (instance, data) {
    // const { currentStep } = instance
    // switch (currentStep.step_key) {
    //   case 'lian02':
    //     return this.businessLeaderBeforeSubmit(data)
    // }
    return Promise.resolve(data)
  }

  // 渲染历史
  renderHistory (action, renderFn) {
    switch (action.step_key) {
      case 'baoquan01':
        return <SecretaryHistory key={action.id} action={action} caseDetail={this.props.caseDetail} />
      // case 'lian02':
      //   return <BusinessLeaderHistory action={action} />
    }
    return renderFn(action)
  }

  // 渲染当前操作步
  renderCurrentStep (form, currentStep, renderFn) {
    // switch (currentStep.step_key) {
    //   case 'lian02':
    //     return <BusinessLeader form={form} currentStep={currentStep} />
    // }
    return renderFn(currentStep)
  }

  render () {
    const { flowInstanceid, flowid } = this.props
    return (
      <Approve
        flowInstanceid={flowInstanceid}
        flowid={flowid}
        renderHistory={(action, renderFn) => this.renderHistory(action, renderFn)}
        renderCurrentStep={(formRef, currentStep, renderFn) => this.renderCurrentStep(formRef, currentStep, renderFn)}
        initialCurrentStepFormData={() => ({ })}
        beforeSubmit={(instance, data) => this.beforeSubmit(instance, data)}
        afterAction={() => this.props.goBack()}
      />
    )
  }
}

Preservation.propTypes = {
  flowInstanceid: PropTypes.string,
  caseDetail: PropTypes.object,
  goBack: PropTypes.func
}

Preservation.defaultProps = {
  flowInstanceid: '',
  caseDetail: {},
  goBack: () => {}
}
