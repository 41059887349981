import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { TextTitle, TextList, TextItem } from '../../TextList'

export default class SecretaryHistory extends Component {
  state = {
    keyTitleMap: {},
    keyValueMap: {}
  }

  // 格式化时间
  formatTime (time) {
    if (time) {
      return moment(time * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  initKeyTitleMap () {
    const map = {}
    const vMap = {}
    const list = _.get(this.props.action || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
      vMap[li.key] = li.value
    })
    this.setState({
      keyTitleMap: map,
      keyValueMap: vMap
    })
  }

  // 格式化金钱
  formatMoney (money) {
    const m = String(money)
    if (!/^\d*$/.test(m)) {
      return m
    }
    return m.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // 格式化时间戳为时间字符串
  formatValueToTime (n) {
    if (n) {
      return moment(n * 1000).format('YYYY-MM-DD')
    }
    return '无'
  }

  componentDidUpdate (prev) {
    if (this.props.action !== prev.action) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  render () {
    const { keyTitleMap, keyValueMap } = this.state
    const d = this.props.action
    return (
      <div>
        <TextTitle rightContent={this.formatTime(d.complete_datetime)}>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作者' value={d.operator} />
          <TextItem title='操作' value={d.action_name} />
          <TextItem title={keyTitleMap.suspension_submittal} value={keyValueMap.suspension_submittal || '无'} />
          <TextItem title={keyTitleMap.suspension_type} value={keyValueMap.suspension_type || '无'} />
          <TextItem title={keyTitleMap.suspension_begin_datetime} value={this.formatValueToTime(keyValueMap.suspension_begin_datetime)} />
          <TextItem title={keyTitleMap.suspension_temp_days} value={keyValueMap.suspension_temp_days ? `${keyValueMap.suspension_temp_days}天` : '无'} />
          <TextItem title={keyTitleMap.suspension_temp_end_datetime} value={this.formatValueToTime(keyValueMap.suspension_temp_end_datetime)} />
          <TextItem title={keyTitleMap.suspension_info} value={keyValueMap.suspension_info || '无'} />
          <TextItem title={d.remark_alias || '备注'} value={d.remark || '无'} />
        </TextList>
      </div>
    )
  }
}

SecretaryHistory.propTypes = {
  action: PropTypes.object
  // caseDetail: PropTypes.object
}

SecretaryHistory.defaultProps = {
  action: {},
  caseDetail: {}
}
