import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { NavBar, Icon, Toast } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import moment from 'moment'
import style from './LetterDetail.module.less'
import { TextList, TextItem } from '../components/TextList'

class LetterDetail extends Component {
  state = {
    height: 0,
    arbitrator: {}
  }

  status = true
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 获取数据
  getData () {
    Toast.loading('数据加载中', 120)
    const id = this.props.match.params.id
    this.$get(`/api/xapc/feedback/${id}`)
      .then(res => {
        Toast.hide()
        if (this.status === false) {
          return false
        }
        if (res.status === 1) {
          this.setState({
            arbitrator: res.data
          })
        } else {
          Toast.fail(res.message, 2)
        }
      }).catch(() => Toast.hide())
  }

  initHeight () {
    this.setState({
      height: this.containerRef.current.offsetHeight - ReactDOM.findDOMNode(this.navbarRef.current).offsetHeight
    })
  }

  formatDate (d) {
    if (!d) {
      return '无'
    }
    return moment(d * 1000).format('YYYY-MM-DD')
  }

  componentDidMount () {
    this.initHeight()
    this.getData()
  }

  componentWillUnmount () {
    this.status = false
  }

  render () {
    const a = this.state.arbitrator
    return (
      <div ref={this.containerRef} className={style.container}>
        <div className={style.header}>
          <NavBar ref={this.navbarRef} mode='light' icon={<Icon type='left' size='lg' />} onLeftClick={() => this.props.history.goBack()}>信访详情</NavBar>
        </div>
        <div className={style.detail} style={{ height: this.state.height + 'px' }}>
          <TextList>
            <TextItem title='案号' value={a.caseno_desc || '无'} />
            <TextItem title='创建时间' value={this.formatDate(a.create_datetime)} />
            <TextItem title='信访内容' value={a.remark || '无'} />
            <TextItem title='处理人' value={a.updater || '无'} />
            <TextItem title='处理结果' value={a.handing_result || '无'} />
            <TextItem title='处理完成时间' value={this.formatDate(a.update_datetime)} />
          </TextList>
        </div>
      </div>
    )
  }
}

LetterDetail.propTypes = {
  goBack: PropTypes.func,
  id: PropTypes.string
}

LetterDetail.defaultProps = {
  goBack: () => {},
  id: ''
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(LetterDetail)
