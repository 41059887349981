import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { List, TextareaItem, Toast } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../../Component'
import { connect } from 'react-redux'
import style from './StepInput.module.less'
import moment from 'moment'

class StepTwoInput extends Component {
  state = {
    keyTitleMap: {}
  }

  initKeyTitleMap () {
    const map = {}
    const list = _.get(this.props.currentStep || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
    })
    this.setState({
      keyTitleMap: map
    })
  }

  // 获取结束时间
  getEndDate (days, setFieldsValue) {
    if (!days) {
      return undefined
    }
    Toast.loading('加载中')
    this.$get('/api/common/days/getEndDatetime', {
      params: {
        begin_datetime: this.props.begin,
        days
      }
    }).then(res => {
      Toast.hide()
      if (res.status !== 1) {
        Toast.fail(res.message)
        return undefined
      }
      const t = moment(res.data * 1000).format('YYYY-MM-DD')
      setFieldsValue({ suspension_check_end_datetime: t })
    })
  }

  componentDidUpdate (prev) {
    if (this.props.currentStep !== prev.currentStep) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  render () {
    const d = this.props.currentStep
    if (!d) {
      return null
    }
    const { getFieldProps, getFieldError, setFieldsValue } = this.props.form
    const remarkTitle = d.remark_alias || '备注'
    const k = this.state.keyTitleMap
    return (
      <div>
        <List renderHeader={() => d.step_name}>
          <List.Item extra={<span className={style.value}>{this.props.userInfo.realname}</span>}>操作者</List.Item>
          <TextareaItem
            {...getFieldProps('suspension_check_days', { rules: [{ required: true }] })}
            error={getFieldError('suspension_check_days')}
            title='调整天数'
            autoHeight
            placeholder={`请输入${k.suspension_check_days}`}
            clear
            required
            onChange={e => {
              setFieldsValue({ suspension_check_days: e })
              this.getEndDate(e, setFieldsValue)
            }}
          />
          <TextareaItem
            {...getFieldProps('suspension_check_end_datetime')}
            title='恢复时间'
            autoHeight
            // placeholder={`请输入${k.suspension_check_end_datetime}`}
            disabled
            clear
          />
          <TextareaItem
            {...getFieldProps('remark')}
            title={remarkTitle}
            autoHeight
            placeholder={`请输入${remarkTitle}`}
            clear
          />
        </List>
      </div>
    )
  }
}

StepTwoInput.propTypes = {
  form: PropTypes.object,
  currentStep: PropTypes.object,
  caseDetail: PropTypes.object,
  otherData: PropTypes.object,
  begin: PropTypes.string
}

StepTwoInput.defaultProps = {
  form: null,
  currentStep: null,
  caseDetail: null,
  otherData: null,
  begin: ''
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(StepTwoInput)
