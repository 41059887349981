import React, { useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle } from 'react'
import Pop from '../Pop/Index'
import style from './Index.module.less'
import { getDataRequest } from './ajax'
import { awaitWrap, limitStr } from '@/assets/js/tool'
import classNames from 'classnames'
import Icon from '@/components/Icon'
import { Toast } from 'antd-mobile'

async function getTree (setMap, setList, setOptions) {
  const [e, d] = await awaitWrap(getDataRequest())
  if (e === null && d !== null) {
    setMap(d)
    setList(d['全部'])
    let l = []
    const keys = Object.keys(d)
    keys.forEach(key => {
      if (key !== '全部') {
        l = [...l, ...d[key]]
      }
    })
    setOptions(l)
  }
}

function Checkbox ({ value, checked = false, indeterminate = false, onChange = () => {} }) {
  const [isChecked, setIsChecked] = useState(checked)
  const [isIndeterminate, setIsIndeterminate] = useState(indeterminate)
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])
  useEffect(() => {
    setIsIndeterminate(indeterminate)
  }, [indeterminate])
  return (
    <label
      className={style.checkbox} onClick={(e) => {
        e.stopPropagation()
        onChange(!isChecked)
        setIsChecked(!isChecked)
      }}
    >
      <span className={style.icon}>
        {isChecked ? <Icon name='select' color='#0039BF' /> : (isIndeterminate ? <span className={style.indeterminate}>&nbsp;</span> : <span>&nbsp;</span>)}
      </span>
      <span>{value}</span>
    </label>
  )
}

function CheckboxGroup ({ visible, value, map, current, setCurrent }) {
  const list = useMemo(() => {
    if (value && map) {
      return map[value] || []
    }
    return []
  }, [value, map])
  const checked = useMemo(() => {
    return list.every(li => current.includes(li))
  }, [list, current])
  const indeterminate = useMemo(() => {
    return list.some(li => current.includes(li))
  }, [list, current])
  if (!visible) {
    return (
      <Checkbox
        value={value} checked={checked} indeterminate={indeterminate} onChange={v => {
          const l = current.filter(li => !list.includes(li))
          if (v) {
            setCurrent([...l, ...list])
          } else {
            setCurrent(l)
          }
        }}
      />
    )
  } else {
    return list.map((li, i) => {
      return <CheckboxItem value={li} key={i} current={current} setCurrent={setCurrent} />
    })
  }
}

function CheckboxItem ({ value, current, setCurrent }) {
  const checked = useMemo(() => {
    return current.includes(value)
  }, [value, current])
  return (
    <Checkbox
      value={value} checked={checked} onChange={v => {
        const l = current.filter(li => li !== value)
        if (v) {
          setCurrent([...l, value])
        } else {
          setCurrent(l)
        }
      }}
    />
  )
}

function Main ({ value = [], onChange = () => {}, onload = () => {} }, oRef) {
  const ref = useRef(null)
  const [map, setMap] = useState({})
  const [list, setList] = useState([])
  const [options, setOptions] = useState([])
  const [current, setCurrent] = useState('')
  const [visible, setVisible] = useState(false)
  const checked = useMemo(() => {
    return value.length === options.length && value.length !== 0
  }, [value, options])
  const indeterminate = useMemo(() => {
    return value.length < options.length && value.length > 0
  }, [value, options])
  useEffect(() => {
    getTree(setMap, setList, setOptions)
  }, [])
  useEffect(() => {
    setCurrent(value)
  }, [value])
  useEffect(() => {
    if (options.length > 0) {
      onload(options)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])
  useImperativeHandle(oRef, () => {
    return {
      reset: () => {
        onChange([...options])
      }
    }
  })
  return (
    <Pop
      ref={ref} over={(
        <div className={style.container}>
          <div className={style['btns-line']}>
            <button onClick={() => {
              onChange([])
              ref.current.hide()
            }}
            >清空
            </button>
            <span className={style['line-title']}>所在区域</span>
            <button onClick={() => {
              if (!current) {
                Toast.info('请选择所在区域', 1)
              }
              onChange(current)
              ref.current.hide()
            }}
            >确定
            </button>
          </div>
          <div className={style.inner}>
            <Checkbox
              value='全部' checked={checked} indeterminate={indeterminate} onChange={v => {
                if (v) {
                  onChange([...options])
                } else {
                  onChange([])
                }
              }}
            />
            {
              list.map((li, i) => {
                if (!map[li]) {
                  return <span key={i} />
                }
                return <CheckboxGroup visible={visible} value={li} map={map} key={i} current={value} setCurrent={onChange} />
              })
            }
            <button className={style['visible-btn']} onClick={() => setVisible(!visible)}>{visible ? '收起' : '展开'}</button>
          </div>
        </div>
      )}
    >
      <div className={style.select}>
        <span className={classNames({ [style.placeholder]: value.length === 0 })}>{limitStr(value.length ? value.join(',') : '请选择', 15)}</span>
        <Icon name='arrow-right' />
      </div>
    </Pop>
  )
}

export default forwardRef(Main)
