import React from 'react'
import { InputItem, Button, Toast } from 'antd-mobile'
import { connect } from 'react-redux'
import requestInstance from '../assets/js/request'
import Border from '../components/Border'
import './Login.less'
import { setLoginMobile, setSendTime, setToken, setUserInfo } from '../redux/actions'
import { compress, decompress } from '@/assets/js/tool'

class Login extends React.Component {
    state = {
      mobile: '',
      verification: '',
      username: '',
      password: '',
      isMobileLogin: true,
      isSending: false,
      countDown: 60
    }

    handleChange (type, value) {
      const obj = {}
      obj[type] = value
      this.setState(obj)
    }

    trim (str) {
      if (typeof str === 'string') {
        return str.trim()
      }
      return str
    }

    /**
     * 获取去除空格的手机号
     */
    getFormMobile () {
      return (this.state.mobile || '').replace(/ /g, '')
    }

    onLogin () {
      const { username, password, verification } = this.state
      Toast.loading('登录中', 60 * 30)
      let param = {}
      let url = '/api/common/vuser_login'
      if (this.state.isMobileLogin) {
        if (!this.validateMobile()) {
          return false
        }
        url = '/api/common/mobile_login'
        param = {
          zoneNum: '86',
          mobile: compress(this.getFormMobile()),
          verification: this.trim(verification)
        }
      } else {
        param = {
          username: this.trim(username),
          password: compress(this.trim(password))
        }
      }
      requestInstance.post(url, param).then(res => {
        Toast.hide()
        if (res.data.status !== 1) {
          Toast.fail(res.data.message, 2)
          return undefined
        }
        const { token, userInfo, mobile, verify_plus: l } = res.data.data
        if (l instanceof Array && l.length > 0) {
          this.props.history.push('/login-validate', { mobile, ...param })
          return undefined
        }
        if (userInfo.mobile && typeof userInfo.mobile === 'string') {
          userInfo.mobile = decompress(userInfo.mobile)
        }
        this.props.setUserInfo(userInfo)
        this.props.setToken(token)
        Toast.success('登录成功', 1, () => {
          if (userInfo.is_expire === 1) {
            this.props.history.push('/admin/modify-password')
          } else {
            this.props.history.push('/admin')
          }
        })
      }).catch(() => {
        Toast.hide()
        Toast.fail('登录失败', 2)
      })
    }

    /**
     * 计时器
     */
    runTime () {
      setTimeout(() => {
        const time = this.state.countDown - 1
        if (time <= 0) {
          this.setState({
            countDown: 60,
            isSending: false
          })
        } else {
          this.setState({
            countDown: time
          }, () => {
            this.runTime()
          })
        }
      }, 1000)
    }

    /**
     * 初始化上一次发送验证码时间
     */
    initSendTime () {
      const lastTime = this.props.sendTime
      const now = Date.now()
      if (!lastTime) {
        return false
      }
      const num = Math.round((now - lastTime) / 1000)
      if (num < 60) {
        this.setState({
          countDown: 60 - num,
          isSending: true
        }, () => {
          this.runTime()
        })
      } else {
        this.props.setSendTime(null)
      }
    }

    /**
     * 发送验证码
     */
    sendSMS () {
      if (this.state.isSending) {
        return false
      }
      const mobile = this.getFormMobile()
      if (!this.validateMobile()) {
        Toast.info('手机号不能为空', 2)
        return undefined
      }
      this.props.setLoginMobile(this.state.mobile)
      this.props.history.push(`/send-sms-validate?mobile=${mobile}`)
    }

    /**
     * 验证手机号码
     */
    validateMobile () {
      let status = true
      const mobile = this.getFormMobile()
      if (!mobile) {
        Toast.info('手机号不能为空')
        status = false
      }
      if (status && !/^1\d{10}$/.test(mobile)) {
        Toast.info('手机号格式不正确')
        status = false
      }
      return status
    }

    /**
     * 发送验证码请求
     */
    sendSMSRequest () {
      if (!this.validateMobile()) {
        return false
      }
      Toast.loading('发送中', 60)
      requestInstance.post('/api/common/sms', {
        mobile: compress(this.getFormMobile()),
        template_code: 'SMS_182920835'
      }).then(res => {
        if (res.status === 200) {
          Toast.info(res.data.message, 2)
          if (res.data.status === 1) {
            this.setState({
              isSending: true,
              countDown: 60
            }, () => {
              this.runTime()
            })
          }
        } else {
          Toast.info('发送信息失败', 2)
        }
      }).catch(() => {
        Toast.info('发送信息失败', 2)
      })
    }

    /**
     * 切换登录方式
     */
    toggleLoginType () {
      this.setState({
        isMobileLogin: !this.state.isMobileLogin,
        mobile: '',
        verification: '',
        username: '',
        password: ''
      })
    }

    initLoginMobile () {
      this.setState({
        mobile: this.props.mobile
      })
    }

    componentDidMount () {
      this.initSendTime()
      this.initLoginMobile()
    }

    /**
     * 渲染账号密码登录框
     */
    renderUsernameForm () {
      return (
        <div>
          <div className='input-block'>
            <Border top={false} left={false} right={false} radius={false}>
              <InputItem
                className='login-input'
                placeholder='请输入'
                clear
                labelNumber={4}
                value={this.state.username}
                autoComplete='new-password'
                onChange={v => this.handleChange('username', v)}
              >
                <span className='require'>账号</span>
              </InputItem>
            </Border>
          </div>
          <div className='input-block'>
            <Border top={false} left={false} right={false} radius={false}>
              <InputItem
                className='login-input'
                type='password'
                placeholder='请输入'
                clear
                labelNumber={4}
                value={this.state.password}
                autoComplete='new-password'
                onChange={v => this.handleChange('password', v)}
              >
                <span className='require'>密码</span>
              </InputItem>
            </Border>
          </div>
        </div>
      )
    }

    /**
     * 渲染手机登录
     */
    renderMobileForm () {
      return (
        <div>
          <div className='input-block'>
            <Border top={false} left={false} right={false} radius={false}>
              <InputItem
                className='login-input'
                type='phone'
                placeholder='请输入'
                clear
                labelNumber={4}
                value={this.state.mobile}
                autoComplete='new-password'
                onChange={v => this.handleChange('mobile', v)}
              >
                <span className='require'>手机号</span>
              </InputItem>
            </Border>
          </div>
          <div className='input-block'>
            <Border top={false} left={false} right={false} radius={false}>
              <div className='validate-line'>
                <InputItem
                  className='login-input'
                  type='number'
                  placeholder='请输入'
                  clear
                  labelNumber={4}
                  maxLength={4}
                  value={this.state.verification}
                  autoComplete='new-password'
                  onChange={v => this.handleChange('verification', v)}
                >
                  <span className='require'>验证码</span>
                </InputItem>
                <div className='validate-btn-container'>
                  <div className={`validate-btn ${this.state.isSending && 'sending'}`} onClick={() => this.sendSMS()}>
                    <span>{this.state.isSending ? `${this.state.countDown}S` : '获取验证码'}</span>
                  </div>
                </div>
              </div>
            </Border>
          </div>
        </div>
      )
    }

    /**
     * 渲染登录表单
     */
    renderLoginForm () {
      if (this.state.isMobileLogin) {
        return this.renderMobileForm()
      }
      return this.renderUsernameForm()
    }

    render () {
      const { username, password, mobile, verification } = this.state
      const btnDisable = this.state.isMobileLogin ? (!mobile || !verification) : (!username || !password)
      return (
        <div className='login-container'>
          <div className='title-line'>
            <img src={require('../assets/imgs/logo.png')} alt='logo' />
            <span>厦仲云办公</span>
          </div>
          <div className='login-type-line'>
            <span>{this.state.isMobileLogin ? '手机号登录' : '账号登录'}</span>
          </div>
          {
            this.renderLoginForm()
          }
          <div className='toggle-type-line'>
            <span onClick={() => this.toggleLoginType()}>{this.state.isMobileLogin ? '用帐号登录' : '用手机号登录'}</span>
          </div>
          <div className='btn-line'>
            <Button type='primary' disabled={btnDisable} onClick={() => this.onLogin()}>登录</Button>
          </div>
        </div>
      )
    }
}

function mapStateToProps (state) {
  return {
    sendTime: state.basic.sendTime,
    mobile: state.basic.mobile
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSendTime: (i) => dispatch(setSendTime(i)),
    setToken: (i) => dispatch(setToken(i)),
    setUserInfo: (i) => dispatch(setUserInfo(i)),
    setLoginMobile: (i) => dispatch(setLoginMobile(i))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
