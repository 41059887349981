import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { TextTitle, TextList, TextItem } from '../../TextList'

export default class StepTwoHistory extends Component {
  state = {
    keyTitleMap: {},
    keyValueMap: {}
  }

  // 格式化时间
  formatTime (time) {
    if (time) {
      return moment(time * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  initKeyTitleMap () {
    const map = {}
    const vMap = {}
    const list = _.get(this.props.action || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
      vMap[li.key] = li.value
    })
    this.setState({
      keyTitleMap: map,
      keyValueMap: vMap
    })
  }

  componentDidUpdate (prev) {
    if (this.props.action !== prev.action) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  render () {
    const { keyTitleMap, keyValueMap } = this.state
    const d = this.props.action
    return (
      <div>
        <TextTitle rightContent={this.formatTime(d.complete_datetime)}>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作者' value={d.operator} />
          <TextItem title='操作' value={d.action_name} />
          <TextItem title={keyTitleMap.zuting_biz_leader_select_info2} value={keyValueMap.zuting_biz_leader_select_info2} />
          <TextItem title={d.remark_alias || '备注'} value={d.remark || '无'} />
        </TextList>
      </div>
    )
  }
}

StepTwoHistory.propTypes = {
  action: PropTypes.object
}

StepTwoHistory.defaultProps = {
  action: {}
}
