import React from 'react'
import PropTypes from 'prop-types'

import { Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

export default class Router extends React.PureComponent {
  render () {
    return this.props.routes.map((c) => {
      return (
        <Route key={c.path} path={c.path} exact={c.exact}>
          {({ match, history }) => {
            return (
              <CSSTransition
                in={match != null}
                timeout={500}
                classNames={match != null ? c.push : c.pop}
                unmountOnExit
                key={c.path}
              >
                <div className='router-wrapper' style={{ zIndex: this.props.zIndex }}>
                  <c.component match={match} history={history} />
                </div>
              </CSSTransition>
            )
          }}
        </Route>
      )
    })
  }
}

Router.propTypes = {
  routes: PropTypes.array,
  zIndex: PropTypes.number
}

Router.defaultProps = {
  routes: [],
  zIndex: 1
}
