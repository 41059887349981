import React from 'react'
import ReactDOM from 'react-dom'
import { NavBar, Icon, ListView, PullToRefresh } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../Component'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Border from '../Border'
import style from './ProcesserSelector.module.less'
import Row from './Row'
class ProcesserSelector extends Component {
  constructor (props) {
    super(props)

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => true
    })

    this.state = {
      dataSource,
      list: [],
      isLoading: false,
      refreshing: false,
      height: document.documentElement.clientHeight * 3 / 4,
      searchText: '',
      total: 0,
      pagination: {
        current: 1,
        page_size: 100
      }
    }
  }

  listRef = React.createRef()
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 到达底部加载更多
  onEndReached () {
    const { list, total, pagination } = this.state
    const { current } = pagination
    if (list.length >= total) {
      return false
    }
    this.setState({
      isLoading: true,
      pagination: {
        ...this.state.pagination,
        current: current + 1
      }
    }, () => {
      this.getData()
    })
  }

  // 初始化列表容器高度
  initListHeight () {
    const totalHeight = this.containerRef.current.offsetHeight
    const nav = ReactDOM.findDOMNode(this.navbarRef.current)
    const navHeight = nav.offsetHeight
    this.setState({
      height: totalHeight - navHeight
    })
  }

  // 获取仲裁员数据
  getData () {
    return this.$get('/api/xapc/employeecase', {
      params: {
        caseid: this.props.caseid,
        causeaction: 0,
        party: 0
      }
    }).then(res => {
      this.setState({
        refreshing: false,
        isLoading: false
      })
      if (res.status === 1) {
        let list = this.state.list.slice()
        list = list.concat(res.data)
        this.setState({
          total: res.data.length,
          list,
          dataSource: this.state.dataSource.cloneWithRows(list)
        })
      }
    }).catch(() => {
      this.setState({
        refreshing: false,
        isLoading: false
      })
    })
  }

  onRefreshHandle () {
    this.setState({
      refreshing: true,
      pagination: {
        ...this.state.pagination,
        current: 1
      },
      list: []
    }, () => {
      this.getData()
    })
  }

  changeSearch (v) {
    this.setState({
      searchText: v,
      pagination: {
        ...this.state.pagination,
        current: 1
      },
      isLoading: true,
      list: [],
      dataSource: this.state.dataSource.cloneWithRows([])
    }, () => {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getData()
      }, 500)
    })
  }

  selectRow (d) {
    this.props.selectHandle(d)
  }

  componentDidMount () {
    this.initListHeight()
    this.setState({
      isLoading: true
    }, () => {
      this.getData()
    })
  }

  componentDidUpdate (prev) {
    const { visible } = this.props
    if (visible && prev.visible !== visible) {
      this.initListHeight()
    }
  }

  renderRow (d, i) {
    return <Row data={d} clickHandle={() => this.selectRow(d)} />
  }

  renderSeparator (d, i) {
    if (i === String(this.state.list.length - 1)) {
      return null
    }
    return (
      <Border key={`${d}-${i}`} top={false} left={false} right={false} radius={false}>
        <div className={style.separator} />
      </Border>
    )
  }

  renderFooter () {
    const { total, list, isLoading } = this.state
    if (list.length >= total && !isLoading) {
      return <div className={style.footer}>没有更多了</div>
    } else {
      return (
        <div className={style.footer}>
          <span className={style['footer-text']}>加载中</span>
          <Icon type='loading' />
        </div>
      )
    }
  }

  render () {
    return (
      <div className={style.container} ref={this.containerRef}>
        <div className={style.header} ref={this.navbarRef}>
          <NavBar ref={this.navbarRef} mode='light' icon={<Icon type='left' size='lg' />} onLeftClick={() => this.props.goBack()}>选择秘书</NavBar>
          <Border left={false} right={false} bottom={false}>
            <div className={style.theader}>
              <div className={style.th} style={{ width: '40%' }}>姓名</div>
              <div className={style.th} style={{ width: '30%', textAlign: 'center' }}>今年分配</div>
              <div className={style.th} style={{ width: '30%', textAlign: 'center' }}>在办</div>
            </div>
          </Border>
        </div>
        <ListView
          ref={this.listRef}
          dataSource={this.state.dataSource}
          renderRow={(d, i) => this.renderRow(d, i)}
          renderSeparator={(d, i) => this.renderSeparator(d, i)}
          style={{
            height: this.state.height,
            overflow: 'auto'
          }}
          pageSize={100}
          initialListSize={100}
          scrollRenderAheadDistance={500}
          onEndReached={() => this.onEndReached()}
          onEndReachedThreshold={10}
          renderFooter={() => this.renderFooter()}
          pullToRefresh={
            <PullToRefresh
              damping={60}
              indicator={this.state.down ? {} : { deactivate: '下拉刷新' }}
              refreshing={this.state.refreshing}
              onRefresh={() => this.onRefreshHandle()}
            />
          }
        />
      </div>
    )
  }
}

ProcesserSelector.propTypes = {
  goBack: PropTypes.func,
  // id: PropTypes.string,
  selectHandle: PropTypes.func
}

ProcesserSelector.defaultProps = {
  goBack: () => {},
  id: '',
  selectHandle: () => {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(ProcesserSelector)
