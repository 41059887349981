import React, { useState, useEffect, useRef } from 'react'
import { Icon } from 'antd-mobile'
import PropTypes from 'prop-types'
import style from './Input.module.less'

function Input ({ value, placeholder, onChange }) {
  const [str, setStr] = useState('')
  const [focus, setFocus] = useState(false)
  const ref = useRef(null)
  useEffect(() => {
    setStr(value)
  }, [value])

  function changeStr (str) {
    if (!focus) {
      setFocus(true)
    }
    setStr(str)
    onChange(str)
  }

  return (
    <div className={style.container}>
      <input
        ref={ref}
        type='text'
        className={style.input}
        value={str}
        placeholder={placeholder}
        onChange={e => changeStr(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setTimeout(() => {
          setFocus(false)
        }, 0)}
      />
      {renderClear(ref, focus, str, changeStr)}
    </div>
  )
}

// 渲染清除按钮
function renderClear (ref, isFocus, str, changeStr) {
  if (!isFocus || str.length === 0) {
    return null
  }
  function click () {
    changeStr('')
    if (ref.current) {
      ref.current.focus()
    }
  }
  return (
    <a className={style.clear} onClick={click}>
      <Icon type='cross-circle-o' size='xxs' />
    </a>
  )
}

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

Input.defaultProps = {
  value: '',
  placeholder: '',
  onChange: () => {}
}

export default Input
