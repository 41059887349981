import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Toast, Result, Icon } from 'antd-mobile'
import { setToken, setUserInfo } from '../redux/actions'
import { getUrlQuery } from '../assets/js/url'
import requestInstance from '../assets/js/request'
import style from './WXLogin.module.less'

class WXLogin extends Component {
  login () {
    const querys = getUrlQuery()
    if (!querys.code) {
      this.props.history.push('/login')
    }
    requestInstance.get('/api/common/wecom_login', {
      params: {
        code: querys.code
      }
    }).then(res => {
      if (res.data.status === 1) {
        const { token, userInfo } = res.data.data
        this.props.setUserInfo(userInfo)
        this.props.setToken(token)
        Toast.success('登录成功', 1, () => {
          this.props.history.push('/admin')
        })
      } else {
        Toast.fail(res.data.message, 2, () => {
          this.props.history.push('/login')
        })
      }
    }).catch(() => {
      Toast.fail('登录失败', 2, () => {
        this.props.history.push('/login')
      })
    })
  }

  componentDidMount () {
    this.login()
  }

  render () {
    return (
      <div className={style.container}>
        <div className='wx-login'>
          <Result
            img={<Icon type='loading' style={{ fill: '#1F90E6', width: '100%', height: '100%' }} />}
            title='企业微信登录中'
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    sendTime: state.basic.sendTime,
    mobile: state.basic.mobile
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setToken: (i) => dispatch(setToken(i)),
    setUserInfo: (i) => dispatch(setUserInfo(i))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WXLogin)
