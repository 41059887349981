import React from 'react'
import { TabBar, Toast, Drawer } from 'antd-mobile'
import { connect } from 'react-redux'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import Icon from '../components/Icon'
import style from './Admin.module.less'
import './Admin.less'
import OtherRouters from '../router/OtherRouters'
import AdminRouteConfig from '../router/AdminRouteConfig'
import pageRouter from '../pages/pageRouter'
import EmptyPage from '../pages/EmptyPage'
import AdminSidebar from '../components/Drawer/AdminSidebar'
import AdminDrawerContext from '../components/Drawer/AdminDrawerContext'
const { Item } = TabBar

class Admin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      selectedTab: '首页',
      arbitratorVisible: false,
      arbitratorId: '',
      detailVisible: false,
      detailType: '',
      detailId: '',
      detailData: null,
      menus: [],
      drawerOpening: false,
      currentDrawer: '',
      arbitratorFormData: {}
    }
    this.setArbitratorFormData = this.setArbitratorFormData.bind(this)
  }

  showArbitratorDetail (id) {
    this.setState({
      arbitratorVisible: true,
      arbitratorId: id
    })
  }

  // 获取菜单
  getMenu () {
    Toast.loading('加载中', 120)
    this.setState({ loading: true })
    this.$get('/api/xapc/myMobileMenu')
      .then(res => {
        Toast.hide()
        this.setState({ loading: false })
        if (res.status !== 1) {
          return Toast.fail(res.message)
        }
        const l = res.data
        if (l.length !== 0) {
          this.setState({
            menus: l[0].children
          })
        }
      })
      .catch(() => {
        this.setState({ loading: false })
        Toast.hide()
      })
  }

  // 显示详情回调
  showDetailHandle (type, id, data) {
    this.setState({
      detailVisible: true,
      detailType: type,
      detailId: id,
      detailData: data
    })
  }

  initExpire () {
    const d = this.props.userInfo
    if (d.is_expire === 1) {
      this.props.history.replace('/admin/modify-password')
    }
  }

  componentDidMount () {
    this.getMenu()
    this.initExpire()
  }

  // 渲染其他菜单
  renderOtherPages () {
    const desktop = this.props.userInfo.desktop
    const m = [{ name: '首页', ico: 'home', url: pageRouter[desktop] ? desktop : 'DefaultDesktop' }, ...this.state.menus, { name: '我的', ico: 'setting', url: 'Mine' }]
    return m.map((item, i) => {
      const n = item.name
      const ico = item.ico
      const Page = pageRouter[item.url] || EmptyPage
      const isMailList = item.url === 'MailList'
      return (
        <Item
          title={n}
          key={i}
          icon={<Icon name={ico} size='1rem' />}
          selectedIcon={<Icon name={`${ico}-filling`} size='1rem' />}
          selected={this.state.selectedTab === n}
          onPress={() => this.setState({ selectedTab: n })}
        >
          <AdminDrawerContext.Consumer>
            {({ arbitratorFormData }) => {
              return (
                <Page history={this.props.history} visible={this.state.selectedTab === n} formData={isMailList ? arbitratorFormData : {}} />
              )
            }}
          </AdminDrawerContext.Consumer>
        </Item>
      )
    })
  }

  setArbitratorFormData (d) {
    this.setState({
      arbitratorFormData: d
    })
  }

  getInitialMap () {
    const { drawerOpening, currentDrawer, arbitratorFormData } = this.state
    return {
      drawerOpening,
      currentDrawer,
      arbitratorFormData,
      setDrawerOpening: drawerOpening => this.setState({ drawerOpening }),
      setCurrentDrawer: currentDrawer => this.setState({ currentDrawer }),
      setArbitratorFormData: this.setArbitratorFormData
    }
  }

  render () {
    return (
      <AdminDrawerContext.Provider value={this.getInitialMap()}>
        <Drawer
          className={style.drawer}
          sidebar={<AdminSidebar />}
          open={this.state.drawerOpening}
          position='right'
          onOpenChange={() => this.setState({ drawerOpening: !this.state.drawerOpening })}
        >
          <div className={style.container} onClick={e => e.preventDefault()}>
            {!this.state.loading ? (
              <div className={style.admin}>
                <TabBar
                  unselectedTintColor='#989898'
                  tintColor='#00478e'
                  barTintColor='white'
                  tabBarPosition='bottom'
                >
                  {this.renderOtherPages()}
                </TabBar>
              </div>
            ) : null}
            <OtherRouters routes={AdminRouteConfig} />
          </div>
        </Drawer>
      </AdminDrawerContext.Provider>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {

  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(Admin)
