import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { createPortal } from 'react-dom'
import style from './Index.module.less'
import classNames from 'classnames'

function Main ({ children, over }, ref) {
  const [visible, setVisible] = useState(false)
  const [acitve, setActive] = useState(false)
  useImperativeHandle(ref, () => {
    return {
      show: () => {
        setVisible(true)
        setTimeout(() => {
          setActive(true)
        }, 100)
      },
      hide: () => {
        setVisible(false)
        setActive(false)
      }
    }
  })
  return (
    <>
      {
        visible && createPortal((
          <div
            className={style.container} onClick={() => {
              setVisible(false)
              setActive(false)
            }}
          >
            <div className={classNames(style.body, { [style.active]: acitve })} onClick={(e) => e.stopPropagation()}>
              {over}
            </div>
          </div>
        ), document.body)
      }
      <div onClick={() => {
        setVisible(true)
        setTimeout(() => {
          setActive(true)
        }, 100)
      }}
      >
        {children}
      </div>
    </>
  )
}

export default forwardRef(Main)
