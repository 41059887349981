import axios from 'axios'
import { Toast } from 'antd-mobile'
import { setToken, setUserInfo } from '../../redux/actions'
import store from '../../redux/store'
import { history } from '../../router/Routers'
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 1000 * 120
})

/**
 * 请求方法
 * @param {*} type 请求类型
 */
function request (type) {
  return function (url, params = {}, config = {}) {
    const { basic } = store.getState()
    const { token, userInfo } = basic
    instance.defaults.headers.Authorization = `Bearer ${token}`
    const that = this
    const data = {
      uid: userInfo.id
      // login_realname: userInfo.realname,
      // login_mobile: userInfo.mobile,
      // login_group_id: userInfo.group_id
    }
    if (type === 'post' || type === 'put') {
      if (params instanceof FormData) {
        config = { ...config, params: { ...config.params, uid: userInfo.id } }
      } else {
        params = Object.assign(params, data)
      }
    } else {
      if (!params.params) {
        params.params = {}
      }
      params.params = Object.assign(params.params, data)
    }
    return new Promise((resolve, reject) => {
      instance[type](url, params, config)
        .then(res => {
          resolve(res.data)
        })
        .catch(e => {
          const { basic } = store.getState()
          const { status, data } = e.response
          if (basic.token === '') {
            if (history.location.pathname !== '/login') {
              history.push('/login')
            }
            return reject(e)
          }
          if (status === 401 || (status === 500 && data.message === '登录超时')) {
            if (!basic.token) {
              return reject(e)
            }
            store.dispatch(setToken(''))
            store.dispatch(setUserInfo({}))
            that && that.props.setToken(undefined)
            Toast.fail(token ? '登录超时' : '请先登录', 2)
            setTimeout(() => {
              history ? history.push('/login') : location.reload()
            }, 2000)
            return reject(e)
          }
          if (status === 504) {
            Toast.fail('网络异常', 2)
          } else if (typeof data.message === 'string') {
            Toast.fail(data.message, 2).then(null, null)
          } else if (status >= 400 && status < 500) {
            Toast.fail('系统网络异常，请联系网络管理员', 2).then(null, null)
          } else {
            Toast.fail('服务器异常，请联系管理员', 2).then(null, null)
          }
          reject(e)
        })
    })
  }
}

export const $get = request('get')
export const $delete = request('delete')
export const $post = request('post')
export const $put = request('put')
