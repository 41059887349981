import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './Row.module.less'

export default class Row extends Component {
  state = {
    active: false
  }

  selectRow (d) {
    this.setState({
      active: true
    }, () => {
      this.props.clickHandle(d)
      setTimeout(() => {
        this.setState({ active: false })
      }, 200)
    })
  }

  render () {
    const d = this.props.data
    const s = [style.row]
    if (this.state.active) {
      s.join(style.active)
    }
    if (this.props.id === d.id) {
      s.join(style.selected)
    }
    return (
      <div className={s.join(' ')} onClick={() => this.selectRow(d)}>
        <div>
          <div className={style.td} style={{ width: '40%' }}>{d.employee}</div>
          <div className={style.td} style={{ width: '30%', textAlign: 'center' }}>{d.caseon}</div>
          <div className={style.td} style={{ width: '30%', textAlign: 'center' }}>{d.caseing}</div>
        </div>
        {d.withdraw ? <div className={style.remark}>回避情况：{d.withdraw}</div> : null}
      </div>
    )
  }
}

Row.propTypes = {
  id: PropTypes.string,
  clickHandle: PropTypes.func,
  data: PropTypes.object
}

Row.defaultProps = {
  id: '',
  clickHandle: () => {},
  data: {}
}
