import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class Icon extends Component {
  render () {
    const { size, color } = this.props
    const style = {}
    if (size) {
      style.fontSize = size
    }
    if (color) {
      style.color = color
    }
    return (
      <span className={`iconfont icon-${this.props.name}`} style={style} />
    )
  }
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string
}

Icon.defaultProps = {
  name: '',
  size: undefined,
  color: undefined
}
