import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import { Toast, PullToRefresh } from 'antd-mobile'
import style from './BookRoomList.module.less'

class FZBookRoomList extends Component {
  state = {
    height: document.documentElement.clientHeight * 3 / 4,
    refreshing: false,
    loading: false,
    todayCourtList: [],
    nextDayCourtList: [],
    nextDayTitle: '明天',
    nextDay: '',
    today: moment().format('YYYY-MM-DD')
  }

  containerRef = React.createRef()

  // 获取开庭信息
  getCourtListByDay (day) {
    return this.$get('/api/xapc/hearingroombook/day', {
      params: {
        day,
        area: '福州厅'
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取开庭信息
  getCourt () {
    const day = moment()
    const dayList = [day.format('YYYY-MM-DD')]
    const dayTitles = ['今天', '明天']
    day.add(1, 'd')
    dayList.push(day.format('YYYY-MM-DD'))
    if (day.day() === 6) {
      day.add(1, 'd')
      dayList.push(day.format('YYYY-MM-DD'))
      day.add(1, 'd')
      dayList.push(day.format('YYYY-MM-DD'))
      dayTitles.push('周日')
      dayTitles.push('下周一')
    }
    this.setState({ loading: true, refreshing: true })
    Promise.all(dayList.map(d => this.getCourtListByDay(d)))
      .then(res => {
        Toast.hide()
        this.setState({ loading: false, refreshing: false })
        this.setState({
          todayCourtList: res[0]
        })
        for (let i = 1; i < res.length; i++) {
          if (res[i].length > 0) {
            this.setState({
              nextDayCourtList: res[i],
              nextDayTitle: dayTitles[i],
              nextDay: dayList[i]
            })
            break
          }
        }
        if (this.state.nextDayCourtList.length === 0) {
          this.setState({
            nextDayTitle: dayTitles[1],
            nextDay: dayList[1]
          })
        }
      }).catch(() => {
        Toast.hide()
        this.setState({ loading: false, refreshing: false })
      })
  }

  formatDate (s) {
    if (s) {
      return moment(s).format('YYYY年MM月DD日')
    }
  }

  getTime (d) {
    if (!d) {
      return d
    }
    return moment(d * 1000).format('HH:mm')
  }

  initHeight () {
    this.setState({
      height: this.containerRef.current.offsetHeight
    })
  }

  componentDidMount () {
    this.initHeight()
    Toast.loading('加载中', 120)
    this.getCourt()
  }

  componentDidUpdate (prev) {
    const { visible } = this.props
    if (visible && prev.visible !== visible) {
      this.initHeight()
    }
  }

  renderList (list) {
    return list.map(li => {
      const arbitrators = []
      if (li.arbitrator) {
        arbitrators.push(li.arbitrator)
      }
      if (li.arbitrator1) {
        arbitrators.push(li.arbitrator1)
      }
      if (li.arbitrator2) {
        arbitrators.push(li.arbitrator2)
      }
      return (
        <div className={style['list-line']} key={li.id}>
          <div className={style['time-cell']}>{this.getTime(li.begin_datetime)}</div>
          <div className={style['name-cell']}>{arbitrators.join('/')}</div>
          <div className={style['secretary-cell']}>{li.operator}</div>
        </div>
      )
    })
  }

  renderEmpty (list) {
    if (list.length === 0) {
      return (
        <div className={style.empty}>无开庭信息</div>
      )
    }
  }

  renderBlock () {
    return (
      <div className={style['block-list']} style={{ minHeight: this.state.height + 'px' }}>
        <div className={style.block}>
          <div className={style['title-line']}>
            <span className={style['title-line__text']}>今日开庭</span>
            <span className={style['title-line__sub-text']}>{this.formatDate(this.state.today)}</span>
          </div>
          <div className={style.list}>
            {
              this.state.todayCourtList.length ? (
                <div className={style['list-title-line']}>
                  <div className={style['time-cell']}>时间</div>
                  <div className={style['name-cell']}>仲裁员</div>
                  <div className={style['secretary-cell']}>秘书</div>
                </div>
              ) : null
            }
            {this.renderList(this.state.todayCourtList)}
            {this.renderEmpty(this.state.todayCourtList)}
          </div>
        </div>

        <div className={style.block}>
          <div className={style['title-line']}>
            <span className={style['title-line__text']}>{this.state.nextDayTitle}开庭</span>
            <span className={style['title-line__sub-text']}>{this.formatDate(this.state.nextDay)}</span>
          </div>
          <div className={style.list}>
            {
              this.state.nextDayCourtList.length ? (
                <div className={style['list-title-line']}>
                  <div className={style['time-cell']}>时间</div>
                  <div className={style['name-cell']}>仲裁员</div>
                  <div className={style['secretary-cell']}>秘书</div>
                </div>
              ) : null
            }
            {this.renderList(this.state.nextDayCourtList)}
            {this.renderEmpty(this.state.nextDayCourtList)}
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className={style.container} ref={this.containerRef}>
        <PullToRefresh
          refreshing={this.state.refreshing}
          damping={60}
          style={{
            height: this.state.height,
            overflow: 'auto'
          }}
          onRefresh={() => this.getCourt()}
        >
          {this.renderBlock()}
        </PullToRefresh>
      </div>
    )
  }
}

FZBookRoomList.propTypes = {
  visible: PropTypes.bool
}

FZBookRoomList.defaultProps = {
  visible: false
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(FZBookRoomList)
