import * as T from './actionTypes'

const initialState = {
  scrollList: [],
  resizeList: []
}

// 设置基本信息
function reducer (state = initialState, action) {
  switch (action.type) {
    case T.ADD_SCROLL_EVENT: // 添加滚动事件处理函数
      return addScrollEvent(state, action.data)
    case T.DEL_SCROLL_EVENT: // 移除滚动事件处理函数
      return delScrollEvent(state, action.data)
    case T.CLEAR_SCROLL_EVENT: // 清空滚动事件处理函数
      return {
        ...state,
        scrollList: []
      }
    case T.ADD_RESIZE_EVENT: // 添加窗口大小改变事件处理函数
      return addResizeEvent(state, action.data) // 移除窗口大小改变事件处理函数
    case T.DEL_RESIZE_EVENT:
      return delResizeEvent(state, action.data) // 清空窗口大小改变事件处理函数
    case T.CLEAR_RESIZE_EVENT:
      return {
        ...state,
        resizeList: []
      }
    default:
      return state
  }
}

/**
 * 添加滚动事件处理函数
 * handleObj({ name: '', handle: () => {} })
 */
function addScrollEvent (state, handleObj) {
  const scrollList = state.scrollList.slice()
  const index = getIndexfromTheList(scrollList, handleObj.name)
  if (index !== -1) {
    console.warn('存在相同或同名的全局滚动事件回调函数,绑定函数失败！')
    return state
  }
  scrollList.push(handleObj)
  return {
    ...state,
    scrollList
  }
}

/**
 * 移除指定的滚动事件处理函数
 */
function delScrollEvent (state, name) {
  const scrollList = state.scrollList.slice()
  const index = getIndexfromTheList(scrollList, name)
  if (index === -1) {
    console.warn('要移除的全局滚动事件回调函数不存在！')
    return state
  }
  scrollList.splice(index, 1)
  return {
    ...state,
    scrollList
  }
}

/**
 * 添加滚动事件处理函数
 */
function addResizeEvent (state, handleObj) {
  const resizeList = state.resizeList.slice()
  const index = getIndexfromTheList(resizeList, handleObj.name)
  if (index !== -1) {
    console.warn('存在相同或同名的窗口大小改变事件回调函数,绑定函数失败！')
    return state
  }
  resizeList.push(handleObj)
  return {
    ...state,
    resizeList
  }
}

/**
 * 移除指定的滚动事件处理函数
 */
function delResizeEvent (state, name) {
  const resizeList = state.resizeList.slice()
  const index = getIndexfromTheList(resizeList, name)
  if (index === -1) {
    console.warn('要移除的窗口大小改变事件回调函数不存在！')
    return state
  }
  resizeList.splice(index, 1)
  return {
    ...state,
    resizeList
  }
}

/**
 * 查找列表中是否有相同或者名称相同的回调函数
 * @param {Array} list 函数列表
 * @param {string} name 回调函数名
 */
function getIndexfromTheList (list, name) {
  let index = -1
  for (let i = 0; i < list.length; i++) {
    if (list[i].name === name) {
      index = i
      break
    }
  }
  return index
}

export default reducer
