import React, { Component } from 'react'
import style from './UnSupport.module.less'
import Icon from '../Icon'

export default class UnSupport extends Component {
  render () {
    return (
      <div className={style.container}>
        <div className={style.message}>
          <div className={style.icon}>
            <Icon name='warning' size='60px' color='#999' />
          </div>
          <div className={style['notice-text']}>
            <span>该呈批暂未配置</span>
            <br />
            <span>请到PC端操作</span>
          </div>
        </div>
      </div>
    )
  }
}
