import { $get } from '@js/authRequest'
import { Toast } from 'antd-mobile'

export async function getDataRequest () {
  const res = await $get('/api/xapc/arbitrator/area')
  if (res.status !== 1) {
    Toast.fail('获取区域列表失败', 1)
    throw new Error(res.message)
  }
  return res.data
}
