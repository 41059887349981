import axios from 'axios'
import { Toast } from 'antd-mobile'
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  timeout: 1000 * 120
})
instance.interceptors.request.use(function (config) {
  // config.url = `/api${config.url}`;
  return config
})
/**
 * 请求方法
 * @param {*} type 请求类型
 */
function request (type) {
  return function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      instance[type](url, params, config)
        .then(res => {
          resolve(res.data)
        })
        .catch(e => {
          const { status, data } = e.response || { status: 504 }
          if (status === 504) {
            Toast.fail('网络异常', 2)
          } else if (typeof data.message === 'string') {
            Toast.fail(data.message, 2).then(null, null)
          } else if (status >= 400 && status < 500) {
            Toast.fail('系统网络异常，请联系网络管理员', 2).then(null, null)
          } else {
            Toast.fail('服务器异常，请联系管理员', 2).then(null, null)
          }
          reject(e)
        })
    })
  }
}

/**
   * 上传文件
   */
export async function submitFiles (files) {
  const { exitFileIds, newFiles } = getDistinctFiles(files)
  const list = newFiles.map(requestOneFile)
  const resList = await Promise.all(list)
  const result = exitFileIds
  resList.forEach(res => {
    if (res.status === 200) {
      result.push(res.data.data.id)
    }
  })
  return result.join()
}

/**
   * 将文件分为已上传文件和未上传文件
   */
export function getDistinctFiles (files) {
  const exitFileIds = []
  const newFiles = []
  files.forEach(file => {
    if (file instanceof File) {
      newFiles.push(file)
    } else {
      exitFileIds.push(file.uid)
    }
  })
  return { exitFileIds, newFiles }
}

/**
   * 提交单个文件
   * @param {*} file 文件对象
   */
export function requestOneFile (file) {
  const formData = new FormData()
  formData.append('upload_file', file)
  // formData.append('api_key', 'z63p7kjbqzxw1yqwm795')
  // formData.append('api_secret', '48mnzza935ww3zfr2sbz1a6ocmd11v')
  // return instance.post('/txapi/common/upload', formData, { 'Content-Type': 'multipart/form-data' })
  return instance.post('/api/common/upload', formData, { 'Content-Type': 'multipart/form-data' })
}

export default instance

export const $get = request('get')
export const $post = request('post')
export const $put = request('put')
export const $delete = request('delete')
