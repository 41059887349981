import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Button, Picker } from 'antd-mobile'
import PropTypes from 'prop-types'
import style from './sidebar.module.less'
import Input from '../Input'
import SelectorTags from '../SelectorTags'
import Dictionary from '../../assets/js/dictionary'
import classNames from 'classnames'
import Icon from '../../components/Icon'
import ProfressionalSelector from '../ProfressionalSelector/Index'
import AreaSelector from '../AreaSelector/Index'

function ArbitratorSidebar ({ onChange, hide }) {
  const areaRef = useRef(null)
  const [professional, setProfessional] = useState('')
  const [area, setArea] = useState([])
  const [causeActionClass, setCauseActionClass] = useState('')
  const [appointDate, setAppointDate] = useState(['全部'])
  const [timeOptions, setTimeOptions] = useState([])
  const [occupation, setOccupation] = useState('')
  const [occupationList, setOccupationList] = useState([])
  const [researchTag, setResearchTag] = useState('')
  const [loaded, setLoaded] = useState(false)
  const initForm = useCallback(() => {
    onChange({ professional, area, causeActionClass, appointDate, occupation, researchTag })
  }, [professional, area, causeActionClass, appointDate, occupation, researchTag, onChange])
  useEffect(() => {
    if (loaded) {
      initForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])
  useEffect(() => {
    const d = new Dictionary()
    d.init('仲裁员聘用日期设置,仲裁员职业分类2')
      .then(() => {
        const arr = d.getList('仲裁员聘用日期设置').map((li) => ({ label: li.title, value: li.value }))
        const l = [{ label: '全部', value: '全部' }, ...arr]
        setTimeOptions(l)
        setOccupationList(d.getList('仲裁员职业分类2').map(li => ({ label: li.title, value: li.value })))
      })
  }, [])
  // 重置
  function reset () {
    setProfessional('')
    setArea('')
    setCauseActionClass('')
    setAppointDate(['全部'])
    setOccupation('')
    areaRef.current.reset()
  }
  return (
    <div className={style.sidebar}>
      <div className={style['sidebar-inner']}>
        <div className={style.title}>擅长专业</div>
        <ProfressionalSelector value={professional} onChange={v => setProfessional(v)} />
        <div className={style.title}>所在区域</div>
        <AreaSelector
          ref={areaRef} value={area} onChange={v => setArea(v)} onload={(v) => {
            setArea(v)
            setLoaded(true)
          }}
        />
        <div className={style.title}>金融专业</div>
        <div className={style.line}>
          <Input value={causeActionClass} onChange={v => setCauseActionClass(v)} placeholder='请输入' />
        </div>
        <div className={style.title}>研究领域</div>
        <div className={style.line}>
          <Input value={researchTag} onChange={v => setResearchTag(v)} placeholder='请输入' />
        </div>
        <div className={style.title}>职业分类</div>
        <div className={style.line}>
          <Picker data={occupationList} cols={1} value={[occupation]} onChange={v => setOccupation(v[0])} onDismiss={() => setOccupation('')} dismissText='清空'>
            <div className={style.select}>
              <span className={classNames({ [style.placeholder]: occupation === '' })}>{occupation || '请选择'}</span>
              <Icon name='arrow-right' />
            </div>
          </Picker>
        </div>
        <div className={style.title}>聘用时间</div>
        <div className={style.line}>
          <SelectorTags options={timeOptions} value={appointDate} onChange={v => setAppointDate(v)} single />
        </div>
      </div>
      <div className={style['operate-btns']}>
        <div className={style.btn}>
          <Button onClick={() => reset(areaRef)}>重置</Button>
        </div>
        <div className={style.btn}>
          <Button type='primary' onClick={() => { hide(); onChange({ professional, area, causeActionClass, appointDate, occupation, researchTag }) }}>确定</Button>
        </div>
      </div>
    </div>
  )
}

ArbitratorSidebar.propTypes = {
  onChange: PropTypes.func,
  hide: PropTypes.func
}

ArbitratorSidebar.defaultProps = {
  onChange: () => {},
  hide: () => {}
}

export default ArbitratorSidebar
