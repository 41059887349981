import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Approve from '../Approve/Index'
import StepOneHistory from './Stop/StepOneHistory'
import StepTwoInput from './Stop/StepTwoInput'
import moment from 'moment'

export default class Reduction extends Component {
  constructor (props) {
    super(props)
    this.state = {
      begin: ''
    }
  }

  // 在提交前处理数据
  beforeSubmit (instance, data) {
    const { currentStep } = instance
    switch (currentStep.step_key) {
      case 'zhongzhi02':
        return this.stepTwoSubmit(data)
    }
    return Promise.resolve(data)
  }

  stepTwoSubmit (data) {
    if (data.suspension_check_end_datetime) {
      data.suspension_check_end_datetime = Math.floor(moment(data.suspension_check_end_datetime).valueOf() / 1000)
    }
    const remark = data.remark
    delete data.remark
    return {
      vars: data,
      remark
    }
  }

  initialCurrentStepFormData (current, otherData) {
    if (current.step_key === 'zhongzhi02') {
      return otherData
    }
    return {}
  }

  initOtherData (history, currentStep) {
    if (currentStep.step_key === 'zhongzhi02') {
      const hList = history.filter(li => li.step_key === 'zhongzhi01').sort((a, b) => b.complete_datetime - a.complete_datetime)
      if (hList.length > 0) {
        const h = hList[0]
        const o = {}
        h.vars.forEach(li => {
          if (li.key === 'suspension_temp_days') {
            o.suspension_check_days = li.value
          } else if (li.key === 'suspension_temp_end_datetime') {
            o.suspension_check_end_datetime = moment(li.value * 1000).format('YYYY-MM-DD')
          } else if (li.key === 'suspension_begin_datetime') {
            this.setState({
              begin: li.value
            })
          }
        })
        return o
      }
    }
    return {}
  }

  // 渲染历史
  renderHistory (action, renderFn) {
    switch (action.step_key) {
      case 'zhongzhi01':
        return <StepOneHistory key={action.id} action={action} caseDetail={this.props.caseDetail} />
    }
    return renderFn(action)
  }

  // 渲染当前操作步
  renderCurrentStep (form, currentStep, renderFn) {
    switch (currentStep.step_key) {
      case 'zhongzhi02':
        return <StepTwoInput form={form} currentStep={currentStep} begin={this.state.begin} />
    }
    return renderFn(currentStep)
  }

  render () {
    const { flowInstanceid, flowid } = this.props
    return (
      <Approve
        flowInstanceid={flowInstanceid}
        flowid={flowid}
        renderHistory={(action, renderFn) => this.renderHistory(action, renderFn)}
        renderCurrentStep={(formRef, currentStep, renderFn) => this.renderCurrentStep(formRef, currentStep, renderFn)}
        initialCurrentStepFormData={this.initialCurrentStepFormData}
        initOtherData={(history, currentStep) => this.initOtherData(history, currentStep)}
        beforeSubmit={(instance, data) => this.beforeSubmit(instance, data)}
        afterAction={() => this.props.goBack()}
      />
    )
  }
}

Reduction.propTypes = {
  flowInstanceid: PropTypes.string,
  // caseDetail: PropTypes.object,
  goBack: PropTypes.func
}

Reduction.defaultProps = {
  flowInstanceid: '',
  caseDetail: {},
  goBack: () => {}
}
