import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Toast, List, InputItem, Icon, TextareaItem } from 'antd-mobile'
import { CSSTransition } from 'react-transition-group'
import Component, { mapStateToProps, mapDispatchToProps } from '../../Component'
import { connect } from 'react-redux'
import style from './StepInput.module.less'
import Selector from '../../ArbitratorSelector/Index'

class StepThreeInput extends Component {
  state = {
    keyTitleMap: {},
    currentNameKey: '',
    currentIdKey: '',
    selectorVisible: false,
    animating: false,
    disabledIds: []
  }

  initKeyTitleMap () {
    const map = {}
    const list = _.get(this.props.currentStep || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
    })
    this.setState({
      keyTitleMap: map
    })
  }

  // 显示选择器
  showSelector (id, name) {
    this.setState({
      currentNameKey: name,
      currentIdKey: id,
      selectorVisible: true,
      animating: true,
      disabledIds: this.getDisableIds(id)
    })
  }

  // 判断是否已经被选择了
  getDisableIds (id) {
    const idkeys = ['arbitratorid1', 'arbitratorid2', 'arbitratorid']
    const index = idkeys.indexOf(id)
    idkeys.splice(index, 1)
    const ids = idkeys.map(key => this.props.form.getFieldValue(key))
    return ids
  }

  // 选择仲裁员
  selectArbitrator (d) {
    const { currentNameKey, currentIdKey } = this.state
    this.props.form.setFieldsValue({
      [currentNameKey]: d.name,
      [currentIdKey]: d.id
    })
    this.setState({
      currentNameKey: '',
      currentIdKey: '',
      animating: false
    })
  }

  // 获取初始化数据
  getInitData () {
    if (!this.props.otherData) {
      return {}
    }
    const court = this.props.otherData.formCourt
    const data = {
      arbitrator: court.arbitrator_recommend,
      arbitratorid: court.arbitratorid_recommend,
      arbitrator1: court.arbitrator1_recommend,
      arbitratorid1: court.arbitratorid1_recommend,
      arbitrator2: court.arbitrator2_recommend,
      arbitratorid2: court.arbitratorid2_recommend
    }
    if (court.arbitratorid_claimant === court.arbitratorid_respondent && court.arbitratorid_claimant) {
      data.arbitrator_recommend = court.arbitrator_claimant
      data.arbitratorid_recommend = court.arbitratorid_claimant
    }
    if (court.arbitratorid1_claimant && court.arbitratorid1_claimant !== court.arbitratorid1_respondent) {
      data.arbitrator1_recommend = court.arbitrator1_claimant
      data.arbitratorid1_recommend = court.arbitratorid1_claimant
    }
    if (court.arbitratorid1_respondent && court.arbitratorid1_respondent !== court.arbitratorid1_claimant) {
      data.arbitrator2_recommend = court.arbitrator1_respondent
      data.arbitratorid2_recommend = court.arbitratorid1_respondent
    }
    return data
  }

  componentDidUpdate (prev) {
    if (this.props.currentStep !== prev.currentStep) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  // 当程序类型为非简易程序时渲染其他两位仲裁员
  renderOtherInput (isNormal, data) {
    if (!isNormal) {
      return null
    }
    const { getFieldProps, getFieldError } = this.props.form
    const labelOne = '仲裁员'
    const labelTwo = '仲裁员'
    return (
      <>
        <span {...getFieldProps('arbitratorid1')} />
        <InputItem
          className='m-select-line'
          labelNumber={labelOne.length + 1}
          {...getFieldProps('arbitrator1', { rules: [{ required: true }] })}
          error={getFieldError('arbitrator1')}
          onErrorClick={() => Toast.info(`${labelOne}不能为空`, 2)}
          placeholder={`请选择${labelOne}`}
          editable={false}
          clear
          onClick={() => this.showSelector('arbitratorid1', 'arbitrator1')}
          extra={<Icon type='right' className={style['right-arrow']} />}
          disabled={!!data.arbitratorid1_recommend}
        >
          {labelOne}
        </InputItem>
        <span {...getFieldProps('arbitratorid2')} />
        <InputItem
          className='m-select-line'
          labelNumber={labelTwo.length + 1}
          {...getFieldProps('arbitrator2', { rules: [{ required: true }] })}
          error={getFieldError('arbitrator2')}
          onErrorClick={() => Toast.info(`${labelTwo}不能为空`, 2)}
          placeholder={`请选择${labelTwo}`}
          editable={false}
          clear
          onClick={() => this.showSelector('arbitratorid2', 'arbitrator2')}
          extra={<Icon type='right' className={style['right-arrow']} />}
          disabled={!!data.arbitratorid2_recommend}
        >
          {labelTwo}
        </InputItem>
      </>
    )
  }

  // 渲染秘书选择器
  renderSelector () {
    if (this.state.selectorVisible) {
      return (
        <div className={style.detail}>
          <Selector
            goBack={() => this.setState({ animating: false })}
            onChange={d => this.selectArbitrator(d)}
            hide={() => this.setState({ animating: false })}
            caseid={this.props.caseDetail.id}
            disabledIds={this.state.disabledIds}
          />
        </div>
      )
    }
    return <div />
  }

  render () {
    const d = this.props.currentStep
    if (!d) {
      return null
    }
    const { getFieldProps, getFieldError } = this.props.form
    const isNormal = this.props.caseDetail.procedure === '非简易程序'
    const labelOne = isNormal ? '首席仲裁员' : '仲裁员'
    const remarkTitle = d.remark_alias || '备注'
    const data = this.getInitData()
    return (
      <div>
        <List renderHeader={() => d.step_name}>
          <List.Item extra={<span className={style.value}>{this.props.userInfo.realname}</span>}>操作者</List.Item>
          <span {...getFieldProps('arbitratorid')} />
          <InputItem
            className='m-select-line'
            labelNumber={labelOne.length + 1}
            {...getFieldProps('arbitrator', { rules: [{ required: true }] })}
            error={getFieldError('arbitrator')}
            onErrorClick={() => Toast.info(`${labelOne}不能为空`, 2)}
            placeholder={`请选择${labelOne}`}
            editable={false}
            clear
            onClick={() => this.showSelector('arbitratorid', 'arbitrator')}
            extra={<Icon type='right' className={style['right-arrow']} />}
            disabled={!!data.arbitratorid_recommend}
          >
            {labelOne}
          </InputItem>
          {this.renderOtherInput(isNormal, data)}
          <TextareaItem
            {...getFieldProps('remark')}
            title={remarkTitle}
            autoHeight
            placeholder={`请输入${remarkTitle}`}
            clear
          />
        </List>
        <CSSTransition
          in={this.state.animating}
          timeout={500}
          classNames={this.state.animating ? 'detail-forward' : 'detail-back'}
          onExited={() => this.setState({ selectorVisible: false })}
        >
          {this.renderSelector()}
        </CSSTransition>
      </div>
    )
  }
}

StepThreeInput.propTypes = {
  form: PropTypes.object,
  currentStep: PropTypes.object,
  caseDetail: PropTypes.object,
  otherData: PropTypes.object
}

StepThreeInput.defaultProps = {
  form: null,
  currentStep: null,
  caseDetail: null,
  otherData: null
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(StepThreeInput)
