import React, { Component } from 'react'
import { Icon } from 'antd-mobile'
import PropTypes from 'prop-types'
import style from './Row.module.less'
import moment from 'moment'

export default class Row extends Component {
  state = {
    active: false
  }

  formatDate (d) {
    if (d) {
      return moment(d * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  selectRowHandle (d) {
    this.setState({
      active: true
    }, () => {
      setTimeout(() => {
        this.setState({ active: false })
      }, 100)
    })
    this.props.selectRowHandle(d, this.props.sectionIndex, this.props.rowIndex)
  }

  render () {
    const { data: d } = this.props
    const rowClass = [style.row]
    const iconClass = [style['row-icon']]
    if (d.multi && d.auto_action) {
      rowClass.push(style['multi-select'])
    }
    if (d.checked) {
      rowClass.push(style.active)
    }
    if (this.state.active) {
      rowClass.push(style.clicked)
    }
    if (d.gender === '女') {
      iconClass.push(style.female)
    }
    return (
      <div className={rowClass.join(' ')} onClick={() => this.selectRowHandle(d)}>
        {d.multi && d.auto_action ? (
          <div className={style['checked-btn']}>
            <Icon type='check-circle-o' />
          </div>
        ) : null}
        <div className={style['row-inner']}>
          <div className={iconClass.join(' ')}>
            <span>{d.operator}</span>
          </div>
          <div className={style['text-block']}>
            <div className={style.title}>
              {d.level ? <span className={style.urgency}>紧急</span> : null}
              <span>{d.content}</span>
            </div>
            <div className={style['sub-title']}>{this.formatDate(d.create_datetime)}</div>
          </div>
        </div>
      </div>
    )
  }
}
Row.propTypes = {
  data: PropTypes.object,
  selectRowHandle: PropTypes.func
}

Row.defaultProps = {
  data: {},
  selectRowHandle: () => {}
}
