import React from 'react'
import PropTypes from 'prop-types'
import Component, { mapStateToProps, mapDispatchToProps } from '../../Component'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { Toast, List, InputItem, TextareaItem } from 'antd-mobile'
import _ from 'lodash'
import style from './BusinessLeader.module.less'
import ProcesserSelector from '../../ProcesserSelector/ProcesserSelector'
class BusinessLeader extends Component {
  state = {
    keyTitleMap: {},
    processerid: '',
    processer: '',
    selectorVisible: false,
    animating: false,
    idKey: '',
    nameKey: ''
  }

  initKeyTitleMap () {
    const map = {}
    const list = _.get(this.props.currentStep || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
    })
    this.setState({
      keyTitleMap: map
    })
  }

  // 选择秘书
  selectProcesser (d) {
    const { idKey, nameKey } = this.state
    this.setState({
      animating: false,
      processer: d.employee,
      processerid: d.employeeid
    }, () => {
      this.props.form.setFieldsValue({
        [nameKey]: d.employee,
        [idKey]: d.employeeid
      })
    })
  }

  // 显示秘书选择器
  showSelector (idKey, nameKey) {
    this.setState({
      animating: true,
      selectorVisible: true,
      idKey,
      nameKey,
      processer: this.props.form.getFieldValue(nameKey),
      processerid: this.props.form.getFieldValue(idKey)
    })
  }

  componentDidUpdate (prev) {
    if (this.props.currentStep !== prev.currentStep) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  // 渲染秘书选择器
  renderSelector () {
    if (this.state.selectorVisible) {
      return (
        <div className={style.detail}>
          <ProcesserSelector goBack={() => this.setState({ animating: false })} selectHandle={d => this.selectProcesser(d)} id={this.state.processerid} />
        </div>
      )
    }
    return <div />
  }

  render () {
    const d = this.props.currentStep
    if (!d) {
      return null
    }
    const { keyTitleMap } = this.state
    const { getFieldProps, getFieldError } = this.props.form
    const processerLabel = keyTitleMap.processer || ''
    const processerTutorLabel = keyTitleMap.processer_tutor || ''
    const remarkTitle = d.remark_alias || '备注'
    return (
      <div>
        <List renderHeader={() => d.step_name}>
          <List.Item extra={<span className={style.value}>{this.props.userInfo.realname}</span>}>操作者</List.Item>
          <span {...getFieldProps('processerid')} />
          <InputItem
            labelNumber={processerLabel.length + 1}
            {...getFieldProps('processer', { rules: [{ required: true }] })}
            error={getFieldError('processer')}
            onErrorClick={() => Toast.info(`${processerLabel}不能为空`, 2)}
            placeholder={`请选择${processerLabel}`}
            editable={false}
            clear
            onClick={() => this.showSelector('processerid', 'processer')}
          >
            {processerLabel}
          </InputItem>
          <span {...getFieldProps('processerid_tutor')} />
          <InputItem
            labelNumber={processerTutorLabel.length + 1}
            {...getFieldProps('processer_tutor')}
            placeholder={`请选择${processerTutorLabel}`}
            editable={false}
            clear
            onClick={() => this.showSelector('processerid_tutor', 'processer_tutor')}
          >
            {processerTutorLabel}
          </InputItem>
          <TextareaItem
            {...getFieldProps('remark')}
            title={remarkTitle}
            autoHeight
            placeholder={`请输入${remarkTitle}`}
            clear
          />
        </List>
        <CSSTransition
          in={this.state.animating}
          timeout={500}
          classNames={this.state.animating ? 'detail-forward' : 'detail-back'}
          onExited={() => this.setState({ selectorVisible: false, idKey: '', nameKey: '', processer: '', processerid: '' })}
        >
          {this.renderSelector()}
        </CSSTransition>
      </div>
    )
  }
}
BusinessLeader.propTypes = {
  form: PropTypes.object,
  currentStep: PropTypes.object
}

BusinessLeader.defaultProps = {
  form: null,
  currentStep: null
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(BusinessLeader)
