import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../../components/Component'
import { connect } from 'react-redux'
import style from './index.module.less'
import { NavBar, Icon, Toast, List, InputItem, Button } from 'antd-mobile'
import classNames from 'classnames'
import NIcon from '../../components/Icon'
import { logout, setLoginMobile, setSendTime } from '../../redux/actions'
import SendCode from '../../components/SendSmsValidate/Index'
import { awaitWrap, compress } from '@/assets/js/tool'
// const Item = List.Item

const maxTime = 60

class ApproveDetail extends Component {
  state = {
    active: false,
    disabled: false,
    password: '',
    code: '',
    visible: false,
    countDown: 0,
    sendVisible: false,
    time: ''
  }

  logout () {
    Toast.show('退出中', 120)
    this.$post('/api/common/logout').then(res => {
      Toast.hide()
      if (res.status === 1) {
        Toast.success('退出成功', 1)
        setTimeout(() => {
          this.props.history.replace('/login')
        }, 1000)
      } else {
        Toast.fail(res.message)
      }
    }, () => {
      Toast.hide()
    })
  }

  /**
     * 计时器
     */
  runTime () {
    setTimeout(() => {
      const time = this.state.countDown - 1
      if (time >= 0) {
        this.setState({
          countDown: time
        }, () => {
          this.runTime()
        })
      } else {
        this.setState({
          disabled: false,
          countDown: 0
        })
      }
    }, 1000)
  }

  /**
   * 初始化上一次发送验证码时间
   */
  initSendTime (lastTime) {
    const now = Date.now()
    if (!lastTime) {
      return false
    }
    const num = Math.round((now - lastTime) / 1000)
    if (num < maxTime) {
      this.setState({
        countDown: maxTime - num,
        disabled: true
      }, () => {
        this.runTime()
      })
    } else {
      this.setState({
        disabled: false,
        countDown: 0
      })
    }
  }

  /**
     * 获取去除空格的手机号
     */
  getFormMobile () {
    return (this.props.userInfo.mobile || '').replace(/ /g, '')
  }

  /**
     * 验证手机号码
     */
  validateMobile () {
    let status = true
    const mobile = this.getFormMobile()
    if (!mobile) {
      Toast.info('手机号不能为空')
      status = false
    }
    if (status && !/^1\d{10}$/.test(mobile)) {
      Toast.info('手机号格式不正确')
      status = false
    }
    return status
  }

  /**
     * 发送验证码
     */
  sendSMS () {
    // const mobile = this.getFormMobile()
    if (!this.validateMobile()) {
      Toast.info('手机号不能为空', 2)
      return undefined
    }
    this.props.setLoginMobile(this.state.mobile)
    this.setState({ sendVisible: true })
  }

  validator () {
    const m = this.state.password
    const c = this.state.code
    if (m.length < 8) {
      return Promise.reject(new Error('新密码最小长度为8位'))
    }
    let count = 0
    if (/[$-]+/.test(m)) {
      return Promise.reject(new Error('新密码不能包含-和$'))
    }
    if (/[a-z]+/.test(m) && /[A-Z]+/.test(m)) {
      count++
    }
    if (/[~!@#%^&*()_+=*/<>?/\\.,;'"]+/.test(m)) {
      count++
    }
    if (/[0-9]+/.test(m)) {
      count++
    }
    if (count < 3) {
      return Promise.reject(new Error('新密码必须包含大小写字母+数字+特殊符号'))
    }
    if (c === '') {
      return Promise.reject(new Error('验证码不能为空'))
    }
    if (this.state.code.length !== 4) {
      return Promise.reject(new Error('验证码格式不正确'))
    }
    return Promise.resolve()
  }

  async submit () {
    const [e] = await awaitWrap(this.validator())
    if (e) {
      Toast.show(e.message, 2)
      return undefined
    }
    Toast.show('提交中', 120)
    this.$post('/api/common/edit_password', {
      mobile: compress(this.props.userInfo.mobile),
      new_password: this.state.password,
      verification: this.state.code
    }).then((res) => {
      if (res.status !== 1) {
        Toast.fail(res.message || '修改密码失败')
      } else {
        Toast.success('修改成功', 1)
        this.props.logout()
        this.props.history.replace('/login')
      }
    }, () => {
      Toast.fail('修改密码失败', 1)
    })
  }

  componentDidMount () {
    if (this.props.sendTime) {
      this.initSendTime(this.props.sendTime)
    }
  }

  componentDidUpdate (props) {
    if (props.sendTime !== this.props.sendTime && this.props.sendTime) {
      this.initSendTime(this.props.sendTime)
    }
  }

  render () {
    const d = this.props.userInfo
    return (
      <div className={style.container} id='modifyPassword'>
        <SendCode visible={this.state.sendVisible} hide={() => this.setState({ sendVisible: false })} />
        <div className={style.header}>
          <NavBar
            ref={this.navbarRef}
            mode='light'
            icon={<Icon type='left' size='lg' />}
            onLeftClick={() => {
              this.props.logout()
              this.props.history.goBack()
            }}
          >修改密码
          </NavBar>
        </div>
        <div className={style.inner}>
          <div className={style.notice}>
            {d.expire_info && <div style={{ marginBottom: '12px' }}>{d.expire_info}</div>}
            <span>新密码必须包含大小写字母+数字+特殊符号，最小长度为8位</span>
          </div>
          <List>
            <InputItem value={this.props.userInfo.mobile} disabled>手机号</InputItem>
            <InputItem
              clear
              placeholder='请输入新密码'
              type={this.state.visible ? 'text' : 'password'}
              value={this.state.password}
              onChange={e => this.setState({ password: e })}
              extra={<span onClick={() => this.setState({ visible: !this.state.visible })}><NIcon name={this.state.visible ? 'browse' : 'hide'} size='1.2rem' /></span>}
            >新密码
            </InputItem>
            <InputItem
              className={style.line}
              clear
              placeholder='请输入验证码'
              maxLength={4}
              value={this.state.code}
              onChange={e => this.setState({ code: e })}
              extra={(
                <button
                  className={classNames(style.send, { [style.active]: this.state.active, [style.disabled]: this.state.disabled })} onClick={() => {
                    if (!this.state.disabled) {
                      this.setState({ active: true })
                      setTimeout(() => {
                        this.setState({ active: false })
                      }, 300)
                      this.sendSMS()
                    }
                  }}
                >
                  {this.state.countDown >= 0 && this.state.disabled ? this.state.countDown : '发送短信'}
                </button>
              )}
            >验证码
            </InputItem>
          </List>
          <div className={style.btn}>
            <Button type='primary' onClick={() => this.submit()}>提交</Button>
          </div>
        </div>
      </div>
    )
  }
}

ApproveDetail.propTypes = {
  // id: PropTypes.string,
}

ApproveDetail.defaultProps = {
  id: '',
  data: {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo,
    sendTime: state.basic.sendTime
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    setLoginMobile: (i) => dispatch(setLoginMobile(i)),
    setSendTime: () => dispatch(setSendTime(Date.now())),
    logout: () => dispatch(logout())
  }
})

export default connect(mapState, mapDispatch)(ApproveDetail)
