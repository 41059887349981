// import React from 'react'
import CaseDetail from '../pages/CaseDetail'

export default [
  {
    path: '/admin/approve-detail/:id/case-detail/:caseid',
    component: CaseDetail,
    push: 'forward',
    pop: 'back',
    exact: true
  }
]
