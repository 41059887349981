// 获取url中的query参数
export function getUrlQuery () {
  const arr = location.search.match(/([^?&'"]+=[^&'"]+)(?!['"])/g) || []
  const result = {}
  arr.forEach(s => {
    s = s.split('=')
    result[s[0]] = s[1]
  })
  return result
}
