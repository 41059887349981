import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './MailRow.module.less'
import Icon from '../Icon'

export default class MailRow extends Component {
  state = {
    active: false
  }

  clickHandle () {
    this.props.clickHandle()
    this.setState({ active: true })
    setTimeout(() => {
      this.setState({ active: false })
    }, 100)
  }

  render () {
    const rowClass = [style.row]
    if (this.state.active) {
      rowClass.push(style.active)
    }
    const d = this.props.data
    return (
      <div className={rowClass.join(' ')} onClick={() => this.clickHandle()}>
        <div className={style['row-inner']}>
          <div className={style['text-block']}>
            <div className={style.title}>{d.name}</div>
            <div className={style['sub-title']}>{d.structure}</div>
          </div>
          <div className={style.arrow}>
            <Icon name='arrow-right' />
          </div>
        </div>
      </div>
    )
  }
}

MailRow.propTypes = {
  data: PropTypes.object,
  clickHandle: PropTypes.func
}

MailRow.defaultProps = {
  data: null,
  clickHandle: () => {}
}
